import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6604a540"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "text-h5" }
const _hoisted_3 = { class: "freecomment" }
const _hoisted_4 = { style: {"font-size":"14px"} }
const _hoisted_5 = { class: "mt-3 ml-3" }
const _hoisted_6 = { class: "text-h5" }
const _hoisted_7 = { class: "mt-2 mb-2" }
const _hoisted_8 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "d-flex mt-5" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, _toDisplayString($setup.title), 1 /* TEXT */)
            ]),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_icon, {
              class: "ml-10",
              color: "#585858",
              style: {"border":"solid 2px"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeBulkDialog')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-window-close")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { class: "justify-start pb-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_checkbox_btn, {
                  modelValue: $setup.isChanegStatus,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.isChanegStatus) = $event)),
                  label: _ctx.$t('word.mailList.statusChange')
                }, null, 8 /* PROPS */, ["modelValue", "label"]),
                _createVNode(_component_v_select, {
                  class: "bulk-select ml-10",
                  "item-title": "status",
                  "item-value": "statusId",
                  items: $props.statusInfoList,
                  "menu-props": { maxHeight: '1080px' },
                  modelValue: $setup.changeTarget.selectStatus,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.changeTarget.selectStatus) = $event)),
                  density: "compact",
                  variant: "outlined",
                  disabled: !$setup.isChanegStatus
                }, null, 8 /* PROPS */, ["items", "modelValue", "disabled"]),
                _createVNode(_component_v_checkbox_btn, {
                  modelValue: $setup.isChanegCategory,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.isChanegCategory) = $event)),
                  label: _ctx.$t('word.mailList.categoryChange')
                }, null, 8 /* PROPS */, ["modelValue", "label"]),
                _createVNode(_component_v_autocomplete, {
                  class: "bulk-select ml-10",
                  "item-title": "category",
                  "item-value": "categoryId",
                  items: $props.categoryInfoList,
                  "menu-props": { maxHeight: '1080px' },
                  modelValue: $setup.changeTarget.selectCategory,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.changeTarget.selectCategory) = $event)),
                  density: "compact",
                  variant: "outlined",
                  disabled: !$setup.isChanegCategory,
                  "no-data-text": _ctx.$t('NS00007')
                }, null, 8 /* PROPS */, ["items", "modelValue", "disabled", "no-data-text"]),
                _createVNode(_component_v_checkbox_btn, {
                  modelValue: $setup.isChanegManager,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.isChanegManager) = $event)),
                  label: _ctx.$t('word.mailList.managerChange')
                }, null, 8 /* PROPS */, ["modelValue", "label"]),
                _createVNode(_component_v_select, {
                  class: "bulk-select ml-10",
                  "item-title": "manager",
                  "item-value": "managerId",
                  items: $props.managerInfoList,
                  "menu-props": { maxHeight: '1080px' },
                  modelValue: $setup.changeTarget.selectManager,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.changeTarget.selectManager) = $event)),
                  density: "compact",
                  variant: "outlined",
                  disabled: !$setup.isChanegManager
                }, null, 8 /* PROPS */, ["items", "modelValue", "disabled"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('word.common.mailInfo.freeCommentAdd')), 1 /* TEXT */),
                  _createVNode(_component_v_textarea, {
                    modelValue: $setup.changeTarget.changeComment,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.changeTarget.changeComment) = $event)),
                    rows: "5",
                    variant: "outlined",
                    "no-resize": "",
                    outlined: "",
                    "bg-color": "white"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_card_actions, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              class: "btn-l text-capitalize",
              color: "blue-darken-1",
              variant: "flat",
              onClick: $setup.showConfirm,
              disabled: !$setup.isBulkChangeRun
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('word.mailList.run')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_dialog, {
      modelValue: $setup.confirmDialog,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.confirmDialog) = $event)),
      persistent: "",
      width: "500px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('word.mailList.confirm')), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_container, { class: "justify-start pb-0" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('NS00105')), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.actionLabelList, (actionLabel) => {
                        return (_openBlock(), _createElementBlock("p", { key: actionLabel }, " 【 " + _toDisplayString(actionLabel) + " 】 ", 1 /* TEXT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('NS00106')), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_v_list, {
                        density: "compact",
                        "max-height": "200"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_subheader, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('word.common.mailInfo.caseId')), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.caseIdList, (item, i) => {
                            return (_openBlock(), _createBlock(_component_v_list_item, {
                              key: i,
                              value: item
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("・" + _toDisplayString(item), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  class: "btn-l",
                  color: "blue-darken-1 text-capitalize",
                  variant: "outlined",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.confirmDialog = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_btn, {
                  class: "btn-l text-capitalize",
                  color: "blue-darken-1",
                  variant: "flat",
                  onClick: $setup.settingBulkChange
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.ok')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}