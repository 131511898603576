/* tslint:disable */
/* eslint-disable */
/**
 * アカウント関連外部API
 * アカウント関連の外部API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * アカウント言語変更結果
 * @export
 * @interface ChangeLanguageAccountResult
 */
export interface ChangeLanguageAccountResult {
    /**
     * 
     * @type {Result}
     * @memberof ChangeLanguageAccountResult
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof ChangeLanguageAccountResult
     */
    'message': string;
    /**
     * 
     * @type {ChangeLanguageAccountResultData}
     * @memberof ChangeLanguageAccountResult
     */
    'data'?: ChangeLanguageAccountResultData | null;
}


/**
 * レスポンスデータ
 * @export
 * @interface ChangeLanguageAccountResultData
 */
export interface ChangeLanguageAccountResultData {
    /**
     * 表示言語（0: 日本語, 1: 英語, 2: 中国語）
     * @type {number}
     * @memberof ChangeLanguageAccountResultData
     */
    'language': number;
    /**
     * 未対応件数
     * @type {number}
     * @memberof ChangeLanguageAccountResultData
     */
    'unsupportedCount': number;
    /**
     * アカウント名
     * @type {string}
     * @memberof ChangeLanguageAccountResultData
     */
    'accountName': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ChangeLanguageAccountResultData
     */
    'updatedAt': string;
}
/**
 * アカウントパスワード変更結果
 * @export
 * @interface ChangePasswordAccountResult
 */
export interface ChangePasswordAccountResult {
    /**
     * 
     * @type {Result}
     * @memberof ChangePasswordAccountResult
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof ChangePasswordAccountResult
     */
    'message': string;
    /**
     * 
     * @type {ChangePasswordAccountResultData}
     * @memberof ChangePasswordAccountResult
     */
    'data'?: ChangePasswordAccountResultData | null;
}


/**
 * レスポンスデータ
 * @export
 * @interface ChangePasswordAccountResultData
 */
export interface ChangePasswordAccountResultData {
    /**
     * アカウントID
     * @type {string}
     * @memberof ChangePasswordAccountResultData
     */
    'accountId': string;
    /**
     * 未対応件数
     * @type {number}
     * @memberof ChangePasswordAccountResultData
     */
    'unsupportedCount': number;
    /**
     * アカウント名
     * @type {string}
     * @memberof ChangePasswordAccountResultData
     */
    'accountName': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ChangePasswordAccountResultData
     */
    'updatedAt': string;
}
/**
 * 管理アカウント作成結果
 * @export
 * @interface CreateAccountResult
 */
export interface CreateAccountResult {
    /**
     * 
     * @type {Result}
     * @memberof CreateAccountResult
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof CreateAccountResult
     */
    'message': string;
    /**
     * 
     * @type {CreateAccountResultData}
     * @memberof CreateAccountResult
     */
    'data'?: CreateAccountResultData | null;
}


/**
 * レスポンスデータ
 * @export
 * @interface CreateAccountResultData
 */
export interface CreateAccountResultData {
    /**
     * アカウントID
     * @type {string}
     * @memberof CreateAccountResultData
     */
    'accountId': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof CreateAccountResultData
     */
    'mailAddress': string;
    /**
     * 未対応件数
     * @type {number}
     * @memberof CreateAccountResultData
     */
    'unsupportedCount': number;
    /**
     * アカウント名
     * @type {string}
     * @memberof CreateAccountResultData
     */
    'accountName': string;
    /**
     * 作成日時
     * @type {string}
     * @memberof CreateAccountResultData
     */
    'createdAt': string;
}
/**
 * アカウント削除結果
 * @export
 * @interface DeleteAccountResult
 */
export interface DeleteAccountResult {
    /**
     * 
     * @type {Result}
     * @memberof DeleteAccountResult
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof DeleteAccountResult
     */
    'message': string;
    /**
     * 
     * @type {DeleteAccountResultData}
     * @memberof DeleteAccountResult
     */
    'data'?: DeleteAccountResultData | null;
}


/**
 * レスポンスデータ
 * @export
 * @interface DeleteAccountResultData
 */
export interface DeleteAccountResultData {
    /**
     * アカウントIDリスト
     * @type {Array<string>}
     * @memberof DeleteAccountResultData
     */
    'accountIds': Array<string>;
    /**
     * 未対応件数
     * @type {number}
     * @memberof DeleteAccountResultData
     */
    'unsupportedCount': number;
    /**
     * アカウント名
     * @type {string}
     * @memberof DeleteAccountResultData
     */
    'accountName': string;
    /**
     * 削除日時
     * @type {string}
     * @memberof DeleteAccountResultData
     */
    'removedAt': string;
}
/**
 * エラーレスポンス(Bad Request)
 * @export
 * @interface ErrorResponseBadRequest
 */
export interface ErrorResponseBadRequest {
    /**
     * 
     * @type {Result}
     * @memberof ErrorResponseBadRequest
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof ErrorResponseBadRequest
     */
    'message': string;
    /**
     * 
     * @type {ErrorResponseBadRequestData}
     * @memberof ErrorResponseBadRequest
     */
    'data'?: ErrorResponseBadRequestData | null;
}


/**
 * レスポンスデータ
 * @export
 * @interface ErrorResponseBadRequestData
 */
export interface ErrorResponseBadRequestData {
    /**
     * エラー項目
     * @type {Array<string>}
     * @memberof ErrorResponseBadRequestData
     */
    'fields': Array<string>;
}
/**
 * エラーレスポンス(Internal Server Error)
 * @export
 * @interface ErrorResponseInternalServerError
 */
export interface ErrorResponseInternalServerError {
    /**
     * 
     * @type {Result}
     * @memberof ErrorResponseInternalServerError
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof ErrorResponseInternalServerError
     */
    'message': string;
    /**
     * レスポンスデータ
     * @type {object}
     * @memberof ErrorResponseInternalServerError
     */
    'data'?: object | null;
}


/**
 * エラーレスポンス(Not Found)
 * @export
 * @interface ErrorResponseNotFound
 */
export interface ErrorResponseNotFound {
    /**
     * 
     * @type {Result}
     * @memberof ErrorResponseNotFound
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof ErrorResponseNotFound
     */
    'message': string;
    /**
     * 
     * @type {ErrorResponseNotFoundData}
     * @memberof ErrorResponseNotFound
     */
    'data'?: ErrorResponseNotFoundData | null;
}


/**
 * レスポンスデータ
 * @export
 * @interface ErrorResponseNotFoundData
 */
export interface ErrorResponseNotFoundData {
    /**
     * アカウントIDリスト
     * @type {Array<string>}
     * @memberof ErrorResponseNotFoundData
     */
    'ids': Array<string>;
}
/**
 * アカウントロック結果
 * @export
 * @interface LockAccountResult
 */
export interface LockAccountResult {
    /**
     * 
     * @type {Result}
     * @memberof LockAccountResult
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof LockAccountResult
     */
    'message': string;
    /**
     * 
     * @type {LockAccountResultData}
     * @memberof LockAccountResult
     */
    'data'?: LockAccountResultData | null;
}


/**
 * レスポンスデータ
 * @export
 * @interface LockAccountResultData
 */
export interface LockAccountResultData {
    /**
     * アカウントID
     * @type {string}
     * @memberof LockAccountResultData
     */
    'accountId': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof LockAccountResultData
     */
    'updatedAt': string;
}
/**
 * アカウント言語変更
 * @export
 * @interface PostParamChangeLanguageAccount
 */
export interface PostParamChangeLanguageAccount {
    /**
     * 表示言語（0: 日本語, 1: 英語, 2: 中国語）
     * @type {number}
     * @memberof PostParamChangeLanguageAccount
     */
    'language': number;
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamChangeLanguageAccount
     */
    'loggedInAccountId': string;
}
/**
 * アカウントパスワード変更
 * @export
 * @interface PostParamChangePasswordAccount
 */
export interface PostParamChangePasswordAccount {
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamChangePasswordAccount
     */
    'accountId': string;
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamChangePasswordAccount
     */
    'loggedInAccountId': string;
}
/**
 * 管理アカウント作成
 * @export
 * @interface PostParamCreateAccount
 */
export interface PostParamCreateAccount {
    /**
     * メールアドレス
     * @type {string}
     * @memberof PostParamCreateAccount
     */
    'mailAddress': string;
    /**
     * アカウント名
     * @type {string}
     * @memberof PostParamCreateAccount
     */
    'displayName': string;
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamCreateAccount
     */
    'loggedInAccountId': string;
}
/**
 * アカウント削除
 * @export
 * @interface PostParamDeleteAccount
 */
export interface PostParamDeleteAccount {
    /**
     * アカウントIDリスト
     * @type {Array<string>}
     * @memberof PostParamDeleteAccount
     */
    'accountIds': Array<string>;
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamDeleteAccount
     */
    'loggedInAccountId': string;
}
/**
 * アカウントロック
 * @export
 * @interface PostParamLockAccount
 */
export interface PostParamLockAccount {
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamLockAccount
     */
    'accountId': string;
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamLockAccount
     */
    'loggedInAccountId': string;
}
/**
 * アカウントロック
 * @export
 * @interface PostParamUnlockAccount
 */
export interface PostParamUnlockAccount {
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamUnlockAccount
     */
    'accountId': string;
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamUnlockAccount
     */
    'loggedInAccountId': string;
}
/**
 * アカウント設定更新
 * @export
 * @interface PostParamUpdateAccount
 */
export interface PostParamUpdateAccount {
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamUpdateAccount
     */
    'accountId': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PostParamUpdateAccount
     */
    'mailAddress': string;
    /**
     * メール通知設定
     * @type {boolean}
     * @memberof PostParamUpdateAccount
     */
    'isMailNoticeOn': boolean;
    /**
     * アカウント名
     * @type {string}
     * @memberof PostParamUpdateAccount
     */
    'displayName': string;
    /**
     * Webhook URL
     * @type {string}
     * @memberof PostParamUpdateAccount
     */
    'slackURL': string;
    /**
     * Slack通知設定
     * @type {boolean}
     * @memberof PostParamUpdateAccount
     */
    'isSlackNoticeOn': boolean;
    /**
     * アカウントステータス
     * @type {boolean}
     * @memberof PostParamUpdateAccount
     */
    'isEnable': boolean;
    /**
     * アカウントID
     * @type {string}
     * @memberof PostParamUpdateAccount
     */
    'loggedInAccountId': string;
    /**
     * 新規タブ表示設定
     * @type {boolean}
     * @memberof PostParamUpdateAccount
     */
    'openMailDetailInNewTab': boolean;
}
/**
 * 結果
 * @export
 * @enum {string}
 */

export const Result = {
    Success: 'success',
    Fail: 'fail'
} as const;

export type Result = typeof Result[keyof typeof Result];


/**
 * アカウントロック解除結果
 * @export
 * @interface UnlockAccountResult
 */
export interface UnlockAccountResult {
    /**
     * 
     * @type {Result}
     * @memberof UnlockAccountResult
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof UnlockAccountResult
     */
    'message': string;
    /**
     * 
     * @type {LockAccountResultData}
     * @memberof UnlockAccountResult
     */
    'data'?: LockAccountResultData | null;
}


/**
 * アカウント設定更新結果
 * @export
 * @interface UpdateAccountResult
 */
export interface UpdateAccountResult {
    /**
     * 
     * @type {Result}
     * @memberof UpdateAccountResult
     */
    'status': Result;
    /**
     * メッセージ or メッセージコード
     * @type {string}
     * @memberof UpdateAccountResult
     */
    'message': string;
    /**
     * 
     * @type {UpdateAccountResultData}
     * @memberof UpdateAccountResult
     */
    'data'?: UpdateAccountResultData | null;
}


/**
 * レスポンスデータ
 * @export
 * @interface UpdateAccountResultData
 */
export interface UpdateAccountResultData {
    /**
     * アカウントID
     * @type {string}
     * @memberof UpdateAccountResultData
     */
    'accountId': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof UpdateAccountResultData
     */
    'mailAddress': string;
    /**
     * アカウント名
     * @type {string}
     * @memberof UpdateAccountResultData
     */
    'displayName': string;
    /**
     * Webhook URL
     * @type {string}
     * @memberof UpdateAccountResultData
     */
    'slackURL': string;
    /**
     * Slack通知設定
     * @type {boolean}
     * @memberof UpdateAccountResultData
     */
    'isSlackNoticeOn': boolean;
    /**
     * アカウントステータス
     * @type {boolean}
     * @memberof UpdateAccountResultData
     */
    'isEnable': boolean;
    /**
     * 未対応件数
     * @type {number}
     * @memberof UpdateAccountResultData
     */
    'unsupportedCount': number;
    /**
     * アカウント名
     * @type {string}
     * @memberof UpdateAccountResultData
     */
    'accountName': string;
    /**
     * 更新日時
     * @type {string}
     * @memberof UpdateAccountResultData
     */
    'updatedAt': string;
}

/**
 * ChangeLanguageAccountApi - axios parameter creator
 * @export
 */
export const ChangeLanguageAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントの言語変更
         * @param {PostParamChangeLanguageAccount} postParamChangeLanguageAccount アカウントの言語を変更する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLanguageAccount: async (postParamChangeLanguageAccount: PostParamChangeLanguageAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postParamChangeLanguageAccount' is not null or undefined
            assertParamExists('changeLanguageAccount', 'postParamChangeLanguageAccount', postParamChangeLanguageAccount)
            const localVarPath = `/changelang`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postParamChangeLanguageAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangeLanguageAccountApi - functional programming interface
 * @export
 */
export const ChangeLanguageAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangeLanguageAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アカウントの言語変更
         * @param {PostParamChangeLanguageAccount} postParamChangeLanguageAccount アカウントの言語を変更する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLanguageAccount(postParamChangeLanguageAccount: PostParamChangeLanguageAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeLanguageAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLanguageAccount(postParamChangeLanguageAccount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChangeLanguageAccountApi.changeLanguageAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ChangeLanguageAccountApi - factory interface
 * @export
 */
export const ChangeLanguageAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangeLanguageAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary アカウントの言語変更
         * @param {PostParamChangeLanguageAccount} postParamChangeLanguageAccount アカウントの言語を変更する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLanguageAccount(postParamChangeLanguageAccount: PostParamChangeLanguageAccount, options?: any): AxiosPromise<ChangeLanguageAccountResult> {
            return localVarFp.changeLanguageAccount(postParamChangeLanguageAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChangeLanguageAccountApi - object-oriented interface
 * @export
 * @class ChangeLanguageAccountApi
 * @extends {BaseAPI}
 */
export class ChangeLanguageAccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウントの言語変更
     * @param {PostParamChangeLanguageAccount} postParamChangeLanguageAccount アカウントの言語を変更する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeLanguageAccountApi
     */
    public changeLanguageAccount(postParamChangeLanguageAccount: PostParamChangeLanguageAccount, options?: AxiosRequestConfig) {
        return ChangeLanguageAccountApiFp(this.configuration).changeLanguageAccount(postParamChangeLanguageAccount, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChangePasswordAccountApi - axios parameter creator
 * @export
 */
export const ChangePasswordAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントのパスワード変更
         * @param {PostParamChangePasswordAccount} postParamChangePasswordAccount アカウントのパスワードを変更する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordAccount: async (postParamChangePasswordAccount: PostParamChangePasswordAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postParamChangePasswordAccount' is not null or undefined
            assertParamExists('changePasswordAccount', 'postParamChangePasswordAccount', postParamChangePasswordAccount)
            const localVarPath = `/changepw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postParamChangePasswordAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangePasswordAccountApi - functional programming interface
 * @export
 */
export const ChangePasswordAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangePasswordAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アカウントのパスワード変更
         * @param {PostParamChangePasswordAccount} postParamChangePasswordAccount アカウントのパスワードを変更する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordAccount(postParamChangePasswordAccount: PostParamChangePasswordAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordAccount(postParamChangePasswordAccount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChangePasswordAccountApi.changePasswordAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ChangePasswordAccountApi - factory interface
 * @export
 */
export const ChangePasswordAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangePasswordAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary アカウントのパスワード変更
         * @param {PostParamChangePasswordAccount} postParamChangePasswordAccount アカウントのパスワードを変更する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordAccount(postParamChangePasswordAccount: PostParamChangePasswordAccount, options?: any): AxiosPromise<ChangePasswordAccountResult> {
            return localVarFp.changePasswordAccount(postParamChangePasswordAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChangePasswordAccountApi - object-oriented interface
 * @export
 * @class ChangePasswordAccountApi
 * @extends {BaseAPI}
 */
export class ChangePasswordAccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウントのパスワード変更
     * @param {PostParamChangePasswordAccount} postParamChangePasswordAccount アカウントのパスワードを変更する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangePasswordAccountApi
     */
    public changePasswordAccount(postParamChangePasswordAccount: PostParamChangePasswordAccount, options?: AxiosRequestConfig) {
        return ChangePasswordAccountApiFp(this.configuration).changePasswordAccount(postParamChangePasswordAccount, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CreateAccountApi - axios parameter creator
 * @export
 */
export const CreateAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 管理アカウントの作成
         * @param {PostParamCreateAccount} postParamCreateAccount 管理アカウントを作成する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (postParamCreateAccount: PostParamCreateAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postParamCreateAccount' is not null or undefined
            assertParamExists('createAccount', 'postParamCreateAccount', postParamCreateAccount)
            const localVarPath = `/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postParamCreateAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreateAccountApi - functional programming interface
 * @export
 */
export const CreateAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreateAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 管理アカウントの作成
         * @param {PostParamCreateAccount} postParamCreateAccount 管理アカウントを作成する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(postParamCreateAccount: PostParamCreateAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(postParamCreateAccount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CreateAccountApi.createAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CreateAccountApi - factory interface
 * @export
 */
export const CreateAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreateAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary 管理アカウントの作成
         * @param {PostParamCreateAccount} postParamCreateAccount 管理アカウントを作成する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(postParamCreateAccount: PostParamCreateAccount, options?: any): AxiosPromise<CreateAccountResult> {
            return localVarFp.createAccount(postParamCreateAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreateAccountApi - object-oriented interface
 * @export
 * @class CreateAccountApi
 * @extends {BaseAPI}
 */
export class CreateAccountApi extends BaseAPI {
    /**
     * 
     * @summary 管理アカウントの作成
     * @param {PostParamCreateAccount} postParamCreateAccount 管理アカウントを作成する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreateAccountApi
     */
    public createAccount(postParamCreateAccount: PostParamCreateAccount, options?: AxiosRequestConfig) {
        return CreateAccountApiFp(this.configuration).createAccount(postParamCreateAccount, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeleteAccountApi - axios parameter creator
 * @export
 */
export const DeleteAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントの削除
         * @param {PostParamDeleteAccount} postParamDeleteAccount アカウントを削除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (postParamDeleteAccount: PostParamDeleteAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postParamDeleteAccount' is not null or undefined
            assertParamExists('deleteAccount', 'postParamDeleteAccount', postParamDeleteAccount)
            const localVarPath = `/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postParamDeleteAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeleteAccountApi - functional programming interface
 * @export
 */
export const DeleteAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeleteAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アカウントの削除
         * @param {PostParamDeleteAccount} postParamDeleteAccount アカウントを削除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(postParamDeleteAccount: PostParamDeleteAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(postParamDeleteAccount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeleteAccountApi.deleteAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DeleteAccountApi - factory interface
 * @export
 */
export const DeleteAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeleteAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary アカウントの削除
         * @param {PostParamDeleteAccount} postParamDeleteAccount アカウントを削除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(postParamDeleteAccount: PostParamDeleteAccount, options?: any): AxiosPromise<DeleteAccountResult> {
            return localVarFp.deleteAccount(postParamDeleteAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeleteAccountApi - object-oriented interface
 * @export
 * @class DeleteAccountApi
 * @extends {BaseAPI}
 */
export class DeleteAccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウントの削除
     * @param {PostParamDeleteAccount} postParamDeleteAccount アカウントを削除する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeleteAccountApi
     */
    public deleteAccount(postParamDeleteAccount: PostParamDeleteAccount, options?: AxiosRequestConfig) {
        return DeleteAccountApiFp(this.configuration).deleteAccount(postParamDeleteAccount, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LockAccountApi - axios parameter creator
 * @export
 */
export const LockAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントのロック
         * @param {PostParamLockAccount} postParamLockAccount アカウントをロックする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockAccount: async (postParamLockAccount: PostParamLockAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postParamLockAccount' is not null or undefined
            assertParamExists('lockAccount', 'postParamLockAccount', postParamLockAccount)
            const localVarPath = `/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postParamLockAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LockAccountApi - functional programming interface
 * @export
 */
export const LockAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LockAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アカウントのロック
         * @param {PostParamLockAccount} postParamLockAccount アカウントをロックする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockAccount(postParamLockAccount: PostParamLockAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockAccount(postParamLockAccount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LockAccountApi.lockAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LockAccountApi - factory interface
 * @export
 */
export const LockAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LockAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary アカウントのロック
         * @param {PostParamLockAccount} postParamLockAccount アカウントをロックする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockAccount(postParamLockAccount: PostParamLockAccount, options?: any): AxiosPromise<LockAccountResult> {
            return localVarFp.lockAccount(postParamLockAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LockAccountApi - object-oriented interface
 * @export
 * @class LockAccountApi
 * @extends {BaseAPI}
 */
export class LockAccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウントのロック
     * @param {PostParamLockAccount} postParamLockAccount アカウントをロックする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockAccountApi
     */
    public lockAccount(postParamLockAccount: PostParamLockAccount, options?: AxiosRequestConfig) {
        return LockAccountApiFp(this.configuration).lockAccount(postParamLockAccount, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UnlockAccountApi - axios parameter creator
 * @export
 */
export const UnlockAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントのロック解除
         * @param {PostParamUnlockAccount} postParamUnlockAccount アカウントのロックを解除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAccount: async (postParamUnlockAccount: PostParamUnlockAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postParamUnlockAccount' is not null or undefined
            assertParamExists('unlockAccount', 'postParamUnlockAccount', postParamUnlockAccount)
            const localVarPath = `/unlock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postParamUnlockAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnlockAccountApi - functional programming interface
 * @export
 */
export const UnlockAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnlockAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アカウントのロック解除
         * @param {PostParamUnlockAccount} postParamUnlockAccount アカウントのロックを解除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockAccount(postParamUnlockAccount: PostParamUnlockAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnlockAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockAccount(postParamUnlockAccount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UnlockAccountApi.unlockAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UnlockAccountApi - factory interface
 * @export
 */
export const UnlockAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnlockAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary アカウントのロック解除
         * @param {PostParamUnlockAccount} postParamUnlockAccount アカウントのロックを解除する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockAccount(postParamUnlockAccount: PostParamUnlockAccount, options?: any): AxiosPromise<UnlockAccountResult> {
            return localVarFp.unlockAccount(postParamUnlockAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnlockAccountApi - object-oriented interface
 * @export
 * @class UnlockAccountApi
 * @extends {BaseAPI}
 */
export class UnlockAccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウントのロック解除
     * @param {PostParamUnlockAccount} postParamUnlockAccount アカウントのロックを解除する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnlockAccountApi
     */
    public unlockAccount(postParamUnlockAccount: PostParamUnlockAccount, options?: AxiosRequestConfig) {
        return UnlockAccountApiFp(this.configuration).unlockAccount(postParamUnlockAccount, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UpdateAccountApi - axios parameter creator
 * @export
 */
export const UpdateAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウント設定の更新
         * @param {PostParamUpdateAccount} postParamUpdateAccount アカウントの設定を更新する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (postParamUpdateAccount: PostParamUpdateAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postParamUpdateAccount' is not null or undefined
            assertParamExists('updateAccount', 'postParamUpdateAccount', postParamUpdateAccount)
            const localVarPath = `/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postParamUpdateAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateAccountApi - functional programming interface
 * @export
 */
export const UpdateAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アカウント設定の更新
         * @param {PostParamUpdateAccount} postParamUpdateAccount アカウントの設定を更新する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(postParamUpdateAccount: PostParamUpdateAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAccountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(postParamUpdateAccount, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UpdateAccountApi.updateAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UpdateAccountApi - factory interface
 * @export
 */
export const UpdateAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary アカウント設定の更新
         * @param {PostParamUpdateAccount} postParamUpdateAccount アカウントの設定を更新する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(postParamUpdateAccount: PostParamUpdateAccount, options?: any): AxiosPromise<UpdateAccountResult> {
            return localVarFp.updateAccount(postParamUpdateAccount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateAccountApi - object-oriented interface
 * @export
 * @class UpdateAccountApi
 * @extends {BaseAPI}
 */
export class UpdateAccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウント設定の更新
     * @param {PostParamUpdateAccount} postParamUpdateAccount アカウントの設定を更新する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateAccountApi
     */
    public updateAccount(postParamUpdateAccount: PostParamUpdateAccount, options?: AxiosRequestConfig) {
        return UpdateAccountApiFp(this.configuration).updateAccount(postParamUpdateAccount, options).then((request) => request(this.axios, this.basePath));
    }
}



