import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22dfb690"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-5 mainMessage" }
const _hoisted_2 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_3 = { class: "subMessage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "card" }, {
    default: _withCtx(() => [
      _createElementVNode("h4", _hoisted_1, _toDisplayString($props.item.main.message), 1 /* TEXT */),
      ($props.item.sub.visivle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString($props.item.sub.message), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            class: "btn",
            color: "blue",
            variant: "outlined",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancelCheck')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_btn, {
            class: "btn",
            color: "blue",
            variant: "flat",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('yesCheck')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.ok')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_spacer)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}