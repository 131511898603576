import {
  ChangeLanguageAccountApi,
  ChangePasswordAccountApi,
  CreateAccountApi,
  DeleteAccountApi,
  UpdateAccountApi
} from '@/axios/webapp/account/api';
import { Configuration } from '@/axios/webapp/account/configuration';

const apiBase = process.env.VUE_APP_WEBAPP_API_BASE;
const config = new Configuration({ basePath: apiBase });
const changeLanguageAccountApi = new ChangeLanguageAccountApi(config);
const changePasswordAccountApi = new ChangePasswordAccountApi(config);
const createAccountApi = new CreateAccountApi(config);
const deleteAccountApi = new DeleteAccountApi(config);
const updateAccountApi = new UpdateAccountApi(config);

export {
  changeLanguageAccountApi,
  changePasswordAccountApi,
  createAccountApi,
  deleteAccountApi,
  updateAccountApi
};