import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
} from "vue-router";
import { Store } from "@/store/store";
import { StoreWrapper } from "@/store/storeWrapper";
import { ROUTE_INFO_LIST, RouteInfoList } from "@/constants/const";
import i18n from "@/constants/language";

// Storeラッパー
const store = StoreWrapper.getInstance(Store.getInstance().getStore());

/**
 * Route設定の読み込み
 * @param ルート設定情報
 */
const loadRoutes = (routerInfoList: RouteInfoList): Array<RouteRecordRaw> => {
  const routeRecords: Array<RouteRecordRaw> = new Array();

  for (const routerInfo of routerInfoList) {
    routeRecords.push({
      path: routerInfo.path,
      name: routerInfo.name,
      component: routerInfo.component,
      meta: { isLogin: routerInfo.isLogin },
    });
  }

  return routeRecords;
};

/**
 * 編集モード時に画面遷移した場合、アラートを出す
 * @param next -ナビゲーションの制御メソッド
 * @returns {boolean} -true :ページ遷移する
 */
const setAlertSetting = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  // 次ページへの遷移フラグ
  let isNext: boolean = true;
  // アラート確認
  if (store.getIsAlert()) {
    const message = i18n.global.t('ES00010');
    // OKの場合、アラートフラグを無効にして次ページへ遷移
    if (window.confirm(message)) store.setIsAlert(false);
    // キャンセルの場合、次ページへの遷移を無効にする
    else isNext = false;
  }

  return next(isNext);
};

// Routerを生成する
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: loadRoutes(ROUTE_INFO_LIST),
});

/**
 * 画面遷移前に、setAlertSettingを実行する
 * @param setAlertSetting -遷移アラートの設定メソッド
 */
router.beforeEach(setAlertSetting);

export default router;
