<template>
  <v-card>
    <v-card-title class="d-flex mt-5">
      <div class="mb-3">
        <span class="text-h5">{{ title }}</span>
      </div>
      <v-spacer />
      <v-icon class="ml-10" color="#585858" style="border: solid 2px"
        @click="$emit('closeBulkDialog')">mdi-window-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-container class="justify-start pb-0">
        <v-checkbox-btn v-model="isChanegStatus" :label="$t('word.mailList.statusChange')" />
        <v-select class="bulk-select ml-10" item-title="status" item-value="statusId" :items="statusInfoList"
          :menu-props="{ maxHeight: '1080px' }" v-model="changeTarget.selectStatus" density="compact" variant="outlined"
          :disabled="!isChanegStatus" />
        <v-checkbox-btn v-model="isChanegCategory" :label="$t('word.mailList.categoryChange')" />
        <v-autocomplete class="bulk-select ml-10" item-title="category" item-value="categoryId"
          :items="categoryInfoList" :menu-props="{ maxHeight: '1080px' }" v-model="changeTarget.selectCategory"
          density="compact" variant="outlined" :disabled="!isChanegCategory" :no-data-text="$t('NS00007')" />
        <v-checkbox-btn v-model="isChanegManager" :label="$t('word.mailList.managerChange')" />
        <v-select class="bulk-select ml-10" item-title="manager" item-value="managerId" :items="managerInfoList"
          :menu-props="{ maxHeight: '1080px' }" v-model="changeTarget.selectManager" density="compact"
          variant="outlined" :disabled="!isChanegManager" />
        <div class="freecomment">
          <h4 style="font-size: 14px;">{{ $t('word.common.mailInfo.freeCommentAdd') }}</h4>
          <v-textarea v-model="changeTarget.changeComment" rows="5" variant="outlined" no-resize outlined
            bg-color="white"></v-textarea>
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="btn-l text-capitalize" color="blue-darken-1" variant="flat" @click="showConfirm"
        :disabled="!isBulkChangeRun">
        {{ $t('word.mailList.run') }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <v-dialog v-model="confirmDialog" persistent width="500px">
    <v-card>
      <v-card-title>
        <div class="mt-3 ml-3">
          <span class="text-h5">{{ $t('word.mailList.confirm') }}</span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container class="justify-start pb-0">
          <p>{{ $t('NS00105') }}</p>
          <div class="mt-2 mb-2">
            <p v-for="actionLabel in actionLabelList" :key="actionLabel">
              【 {{ actionLabel }} 】
            </p>
          </div>
          <p>{{ $t('NS00106') }}</p>
          <div class="mt-5">
            <v-list density="compact" max-height="200">
              <v-list-subheader>{{ $t('word.common.mailInfo.caseId') }}</v-list-subheader>
              <v-list-item v-for="(item, i) in caseIdList" :key="i" :value="item">
                <v-list-item-title>・{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn-l" color="blue-darken-1 text-capitalize" variant="outlined" @click="confirmDialog = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn class="btn-l text-capitalize" color="blue-darken-1" variant="flat" @click="settingBulkChange">
          {{ $t('common.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { CategoryInfo } from '@/axios/admin/manageCategory';
import { ManagerInfo, StatusInfo } from '@/axios/mail/mailList/api';
import i18n from '@/constants/language';
import _ from 'lodash';
import { PropType, computed, ref } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { StoreWrapper } from '@/store/storeWrapper';
// Storeラッパー
const store = StoreWrapper.getInstance();

const props = defineProps({
  /**
   * 案件IDのリスト
   */
  caseIdList: {
    type: Array as PropType<String[]>,
    required: true
  },
  /**
   * ステータスのリスト
   */
  statusInfoList: {
    type: Array as PropType<StatusInfo[]>,
    required: true
  },
  /**
   * 担当者のリスト
   */
  managerInfoList: {
    type: Array as PropType<ManagerInfo[]>,
    required: true
  },
  /**
   * 分類のリスト
   */
  categoryInfoList: {
    type: Array as PropType<CategoryInfo[]>,
    required: true
  }
})

const emit = defineEmits(["settingBulkChange", "closeBulkDialog"])

interface ChangeTarget {
  selectStatus: number | null,
  selectCategory: number | null,
  selectManager: string | null,
  changeComment: string
}

props.caseIdList, props.statusInfoList, props.managerInfoList, props.categoryInfoList;

let changeTarget = ref<ChangeTarget>({
  selectStatus: props.statusInfoList?.[0]?.statusId ?? null,
  selectCategory: props.categoryInfoList?.[0]?.categoryId ?? null,
  selectManager: props.managerInfoList?.[0]?.managerId ?? null,
  changeComment: ""
}
);

let isChanegStatus = ref<boolean>(false);
let isChanegCategory = ref<boolean>(false);
let isChanegManager = ref<boolean>(false);
let confirmDialog = ref<boolean>(false);
let actionLabelList: string[] = [];
let title = i18n.global.t('NS00206', {caseId: props.caseIdList[0]});
if(store.getPageName() === 'mailList') title = i18n.global.t('word.mailList.bulkChange');

/**
 * フリーコメントの編集可否
 */
const isChanegComment = computed(() => changeTarget.value.changeComment.trim().length >= 1);

/**
 * 実行ボタンの活性条件を設定する
 */
const isBulkChangeRun = computed(() => isChanegStatus.value && changeTarget.value.selectStatus !== null
  || isChanegCategory.value && changeTarget.value.selectCategory !== null
  || isChanegManager.value && changeTarget.value.selectManager !== null
  || isChanegComment.value);

/**
 * 一括変更確認ダイアログを開く
 * @returns {void}
 */
const showConfirm = (): void => {
  if (
    !isChanegStatus.value &&
    !isChanegCategory.value &&
    !isChanegManager.value &&
    !isChanegComment.value
  ) return;

  actionLabelList = new Array();

  if (isChanegStatus.value) {
    const status = props.statusInfoList.filter(item => item.statusId === changeTarget.value.selectStatus);
    if (!_.isEmpty(status)) actionLabelList.push(`${i18n.global.t('word.mailList.statusChange')} : ${status[0].status}`);
  }
  if (isChanegCategory.value) {
    const category = props.categoryInfoList.filter(item => item.categoryId === changeTarget.value.selectCategory);
    if (!_.isEmpty(category)) actionLabelList.push(`${i18n.global.t('word.mailList.categoryChange')} : ${category[0].category}`);
  }
  if (isChanegManager.value) {
    const manager = props.managerInfoList.filter(item => item.managerId === changeTarget.value.selectManager);
    if (!_.isEmpty(manager)) actionLabelList.push(`${i18n.global.t('word.mailList.managerChange')} : ${manager[0].manager}`);
  }
  if (isChanegComment.value) {
    actionLabelList.push(`${i18n.global.t('word.common.mailInfo.freeCommentAdd')} : ${changeTarget.value.changeComment}`);
  }
  confirmDialog.value = true;
  return;
}

/**
 * 一括変更APIのリクエストパラメータの設定をする
 * @returns {void}
 */
const settingBulkChange = async (): Promise<void> => {
  // チェックボックスがオフの変更対象にはnullをセットする
  if (!isChanegStatus.value) {
    changeTarget.value.selectStatus = null;
  }
  if (!isChanegCategory.value) {
    changeTarget.value.selectCategory = null;
  }
  if (!isChanegManager.value) {
    changeTarget.value.selectManager = null;
  }

  confirmDialog.value = false;

  emit('settingBulkChange', changeTarget.value);

  return;
}
</script>

<style scoped>
.bulk-select {
  min-width: 300px;
  max-width: 300px;
}

.freecomment {
  min-width: 500px;
  max-width: 500px;
}
</style>