import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/DMS_title.png'


const _withScopeId = n => (_pushScopeId("data-v-29e8c3c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headerImage" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "box" }
const _hoisted_5 = { class: "pt-1 header-text" }
const _hoisted_6 = { class: "pt-3 ml-2 header-text" }
const _hoisted_7 = { class: "pt-3 ml-2 header-text" }
const _hoisted_8 = { class: "languageSelect" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_checkDialog = _resolveComponent("checkDialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app_bar, {
      style: {"min-width":"1900px","background-color":"black"},
      absolute: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            style: {"width":"270px"},
            id: "system-img",
            src: _imports_0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push($setup.PAGE_INFO.MAIL.MAIL_LIST.PATH)))
          })
        ]),
        _createVNode(_component_v_spacer),
        ($setup.isAuthenticated)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("a", _hoisted_5, _toDisplayString($setup.store.getRoleName()), 1 /* TEXT */)
                ]),
                _createElementVNode("a", _hoisted_6, "[ " + _toDisplayString($setup.store.getCompanyName()) + " ]", 1 /* TEXT */),
                _createElementVNode("a", _hoisted_7, _toDisplayString($setup.store.getAccountName()), 1 /* TEXT */),
                _createVNode(_component_v_btn, {
                  variant: "outlined",
                  class: "logoutBtn text-capitalize",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.openDialog(_ctx.$t('NS00001'))))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.logout')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_v_select, {
                  items: $setup.langaegeInfoList,
                  modelValue: $setup.language,
                  "onUpdate:modelValue": [
                    _cache[2] || (_cache[2] = ($event: any) => (($setup.language) = $event)),
                    $setup.changeLang
                  ],
                  "item-title": "languageValue",
                  "item-value": "languageId",
                  class: "mr-4 custom-select",
                  "prepend-inner-icon": "mdi-web",
                  variant: "outlined",
                  density: "compact",
                  "hide-details": ""
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_dialog, {
      modelValue: $setup.isDialog,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.isDialog) = $event)),
      persistent: "",
      width: "500"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_checkDialog, {
          item: $setup.checkDialogItems,
          onYesCheck: $setup.execLogout,
          onCancelCheck: _cache[3] || (_cache[3] = ($event: any) => ($setup.isDialog = false))
        }, null, 8 /* PROPS */, ["item"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}