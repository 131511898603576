import { AxiosRequestConfig } from "axios";

// リクエスト設定を生成
export const createRequestConfig = (accessToken: string): AxiosRequestConfig => {
  
  // リクエスト設定(カスタムヘッダを設定)
  const axiosRequestConfig: AxiosRequestConfig = {
    headers: { Authorization: accessToken }
  };

  return axiosRequestConfig;
};
