<template>
  <v-app>
    <my_header />
    <link_tab v-show="isAuthenticated" />
    <router-view v-show="isAuthenticated" />
  </v-app>
</template>

<script setup lang="ts">
import { StoreWrapper } from '@/store/storeWrapper';
import router from '@/router';
import { watchEffect } from "vue";
import my_header from "./components/header.vue";
import link_tab from "./components/menu.vue";
import { ROLE_TYPE, PAGE_PATH_LIST, PAGE_INFO,  } from '@/constants/const';
import { useAuth0 } from "@auth0/auth0-vue";
import { checkRole,  } from '@/functions/util';

// Auth0のAPIモジュール
const { isLoading, isAuthenticated, user, loginWithRedirect, error } = useAuth0();

// Storeラッパー
const store = StoreWrapper.getInstance();
// 認証状態のチェック回数
let checkCount: number = 0;
// 認証状態の最大チェック回数 ⇒ ( チェック回数 - 1 )秒でタイムアウト
const CHECK_COUNT_MAX = 3;
// 末尾の「/」を取り除いた現URL
const locationHref = location.href.endsWith('/') ? location.href.slice(0, -1) : location.href;
// Auth0のリダイレクト後か
const isRedirectedByAuth0 = locationHref.includes(`${PAGE_INFO.MAIL.MAIL_LIST.PATH}?`);
let pageName: string = '';

// 無効なURLか確認
const isInvalidUrl = (url: string): boolean => {
  let result = true;

  // ページのURLリストに存在すれば有効
  for (const pagePath of PAGE_PATH_LIST) {
    if (url.endsWith(pagePath)) {
      result = false;
      break;
    }
  }

  return result;
};

// 監視処理
watchEffect(() => {
  pageName = store.getPageName();

  // 権限チェック
  checkRole(pageName);

  return;
});

// アカウント認証エラーの場合、ログイン画面へリダイレクト
watchEffect(async () => {
  if (store.getIsAccountAuthError()) {
    store.clearStoreData();
    await loginWithRedirect();
  }
  return;
});

watchEffect(() => {
  if (error.value) console.log(`watchEffect ⇒ error: ${error.value}, isRedirectedByAuth0: ${isRedirectedByAuth0}`);
});

const checkIsAuthenticated = async () => {
  checkCount++;
  if (error.value) console.log(`error: ${error.value}, locationHref: ${locationHref}`);

  if (!isLoading.value && isAuthenticated.value) {

    // 認証状態をストアに保持
    store.setIsAuthenticated(isAuthenticated.value);

    // 最新のアカウント情報をストアに保存
    store.setAccountId(user.value?.sub?.replace('auth0|', '') ?? '');
    store.setAccountEmail(user.value?.email ?? '');
    store.setAccountRole(Number(user.value?.role ?? ROLE_TYPE.NONE));

    // 表示名と言語切替のセット（Todo）
    // metaDataから取得
    store.setAccountName(user.value?.metadata.accountName ?? '');
    store.setCompanyName(user.value?.metadata.companyName ?? '');
    store.setIsNewTab(user.value?.metadata.openMailDetailInNewTab ?? false);

    // 無効なURLの場合、メール一覧ページへ遷移
    if (isInvalidUrl(locationHref) && !isRedirectedByAuth0) {
      return await router.push(PAGE_INFO.MAIL.MAIL_LIST.PATH);
    }

  } else if (checkCount < CHECK_COUNT_MAX) {
    // 1秒後に再度チェック
    setTimeout(checkIsAuthenticated, 1000);
  } else {
    // storeを初期化
    store.clearStoreData();

    // 停止されたアカウントでのログインをブロック
    if (error.value && error.value.message.includes('user is blocked')) {
      alert("Your account is locked.");
    }

    // タイムアウトで未認証の為、ログインページにリダイレクト
    await loginWithRedirect();
  }

  return;
}

(async () => {
  if (!locationHref.includes(`${PAGE_INFO.MAIL.MAIL_LIST.PATH}?code`) && !locationHref.includes(`${PAGE_INFO.MAIL.MAIL_LIST.PATH}?error`)) await checkIsAuthenticated();

  return;
})();

</script>

<style>
#app {
  text-align: center;
}

.v-main {
  background-color: #f2f2f2;
}

.v-container {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.full-hd-flame {
  min-width: 1800px;
}

.hd-flame {
  min-width: 1100px;
}

.font-10{
  font-size: 10px;
}

.font-12{
  font-size: 12px;
}

.font-14{
  font-size: 14px;
}

.column {
  text-align: left;
  font-weight: bold !important;
}

h4 {
  font-size: 15px;
  text-align: left;
}

.btn-s {
  width: 50px;
  height: 35px !important;
}

.btn-m {
  width: 100px;
  height: 45px !important;
}

.btn-l {
  margin: 5px 5px;
  width: 130px;
  height: 40px !important;
}

.btn-xl {
  width: 150px;
  height: 50px !important;
}

.itemName {
  font-size: 14px;
  width: 240px;
  text-align: left;
  margin-top: 10px;
}

.itemName2 {
  font-size: 14px;
  width: 200px;
  text-align: left;
  margin-top: 10px;
  margin-left: 15px;
}

.red {
  color: red;
}
</style>