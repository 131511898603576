<template>
  <v-card>
    <v-card-title class="d-flex mt-5">
      <div class="mb-3">
        <span v-if="!isFilter" class="text-h5" style="font-weight: bold">{{ $t('common.detailedSearch') }}</span>
        <span v-if="isFilter" class="text-h5" style="font-weight: bold">{{ $t('word.mailList.filter') }}</span>
      </div>
      <v-spacer />
      <v-icon class="ml-10" color="#585858" style="border: solid 2px"
        @click="$emit('closeDialog')">mdi-window-close</v-icon>
    </v-card-title>

    <v-card-text>
      <v-container>
        <div class="d-flex justify-start" v-if="!isFilter">

          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.sendDateStart') }}</h4>
            <VueDatePicker v-model="searchCondition.sendDateStart" :value="searchCondition.sendDateStart"
              :format="'yyyy-MM-dd HH:mm'" model-type="yyyy-MM-dd HH:mm:ss" @date-update="sendDateStartDateClicked"
              text-input @text-input="handleSendDateStartInput" :placeholder="'yyyy-MM-dd HH:mm'">
              <template #time-picker="{ time }">
                <div class="custom-time-picker-component">
                  <select class="select-input" :value="time.hours" @change="hourSelected(true, $event)">
                    <option v-for="h in hoursArray" :key="h.value" :value="h.value">{{ h.text }}</option>
                  </select>
                  <select class="select-input" :value="time.minutes" @change="minuteSelected(true, $event)">
                    <option v-for="m in minutesArray" :key="m.value" :value="m.value">{{ m.text }}</option>
                  </select>
                </div>
              </template>
            </VueDatePicker>
          </div>

          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.sendDateEnd') }}</h4>
            <VueDatePicker v-model="searchCondition.sendDateEnd" :format="'yyyy-MM-dd HH:mm'"
              model-type="yyyy-MM-dd HH:mm:ss" @date-update="sendDateEndDateClicked" text-input
              @text-input="handleSendDateEndInput" :placeholder="'yyyy-MM-dd HH:mm'">
              <template #time-picker="{ time }">
                <div class="custom-time-picker-component">
                  <select class="select-input" :value="time.hours" @change="hourSelected(false, $event)">
                    <option v-for="h in hoursArray" :key="h.value" :value="h.value">{{ h.text }}</option>
                  </select>
                  <select class="select-input" :value="time.minutes" @change="minuteSelected(false, $event)">
                    <option v-for="m in minutesArray" :key="m.value" :value="m.value">{{ m.text }}</option>
                  </select>
                </div>
              </template>
            </VueDatePicker>
          </div>
        </div>

        <div class="d-flex justify-start mt-2" v-if="!isFilter">
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.inquiryNo') }}</h4>
            <v-text-field class="search-select" v-model="searchCondition.inquiryId" variant="outlined" density="compact"
              hide-details :maxlength=MAX_INPUT_LENGTH.INQUIRY_ID></v-text-field>
          </div>
          <div class="pr-3 ">
            <h4>{{ $t('word.common.mailInfo.caseId') }}</h4>
            <v-text-field class="search-select" v-model="searchCondition.caseId" variant="outlined" density="compact"
              hide-details :maxlength=MAX_INPUT_LENGTH.CASE_ID></v-text-field>
          </div>
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.userId') }}</h4>
            <v-text-field class="search-select" v-model="searchCondition.userId" variant="outlined" density="compact"
              hide-details :maxlength=MAX_INPUT_LENGTH.USER_ID></v-text-field>
          </div>
        </div>

        <div class="d-flex justify-start mt-2">
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.answerLimit') }}</h4>
            <v-select class="search-select" :items="answerLimitMap" item-title="label" item-value="value"
              :menu-props="{ maxHeight: '1080px' }" v-model="searchCondition.answerLimitList" density="compact"
              variant="outlined" hide-details multiple chips />
          </div>
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.status') }}</h4>
            <v-select class="search-select" item-title="status" item-value="statusId" :items="statusInfoList"
              :menu-props="{ maxHeight: '1080px' }" density="compact" variant="outlined" multiple
              v-model="searchCondition.statusIdList" hide-details chips />
          </div>
        </div>

        <div class="d-flex justify-start mt-2">
          <div class="pr-3" v-if="!isFilter">
            <h4>{{ $t('word.common.mailInfo.category') }}</h4>
            <v-autocomplete class="w360" item-title="category" item-value="categoryId" :items="categoryInfoList"
              :menu-props="{ maxHeight: '1080px' }" density="compact" variant="outlined" multiple clear-on-select
              v-model="searchCondition.categoryIdList" hide-details chips :no-data-text="$t('NS00007')" />
          </div>
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.manager') }}</h4>
            <v-select class="w360" item-title="manager" :menu-props="{ maxHeight: '1080px' }" item-value="managerId"
              :items="managerInfoList" v-model="searchCondition.managerIdList" density="compact" variant="outlined"
              multiple hide-details chips />
          </div>
        </div>

        <div class="d-flex justify-start mt-2">
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.subject') }}</h4>
            <v-text-field class="search-input" v-model="searchCondition.subject" variant="outlined" density="compact"
              hide-details :maxlength=MAX_INPUT_LENGTH.SUBJECT></v-text-field>
          </div>
        </div>
        <div class="d-flex justify-start mt-2">
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.mailbody') }}</h4>
            <v-text-field class="search-input" v-model="searchCondition.mailBody" variant="outlined" density="compact"
              hide-details :maxlength=MAX_INPUT_LENGTH.MAIL_BODY_SEARCH_FILTER></v-text-field>
          </div>
        </div>
        <div class="d-flex justify-start mt-2" v-if="!isFilter">
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.logDetail') }}</h4>
            <v-text-field class="search-input" v-model="searchCondition.logDetail" variant="outlined" density="compact"
              hide-details :maxlength=MAX_INPUT_LENGTH.LOG_DETAIL_SEARCH_FILTER></v-text-field>
          </div>
        </div>
        <div class="d-flex justify-start mt-2" v-if="!isFilter">
          <div class="pr-3">
            <h4>{{ $t('word.common.mailInfo.freeComment') }}</h4>
            <v-text-field class="search-input" v-model="searchCondition.comment" variant="outlined" density="compact"
              hide-details :maxlength=MAX_INPUT_LENGTH.FREE_COMMENT_SEARCH_FILTER></v-text-field>
          </div>
        </div>

        <div class="d-flex justify-start mt-2" v-if="!isFilter">
          <div class="pr-3 mt-5">
            <h4 class="mb-1">{{ $t('word.common.mailInfo.presetCondition') }}</h4>
            <v-chip-group v-model="selectingPreset" @update:model-value="handlePresetClick">
              <v-chip class="chip" v-for="item in presets" :key="item.presetKey" :text="item.presetValue"
                :value="item.presetKey" variant="flat" color="orange"></v-chip>
            </v-chip-group>
          </div>
        </div>

        <div class="d-flex justify-start mt-4" v-if="isFilter">
          <div class="pr-3">
            <h4>{{ $t('word.mailList.sortConditions') }}</h4>
            <v-select class="search-select" :items="mapArray" item-title="label" item-value="value"
              :menu-props="{ maxHeight: '1080px' }" v-model="searchCondition.sort" density="compact" variant="outlined"
              hide-details />
          </div>

          <div class="mt-6 d-flex" v-if="searchCondition.isAsc">
            <v-btn variant="flat" color="blue" width="100" height="30" class="text-capitalize">{{
          $t('word.mailList.ascending')
        }}</v-btn>

            <v-btn class="ml-3 text-capitalize" @click="searchCondition.isAsc = false" variant="outlined" color="blue"
              width="100" height="30">{{ $t('word.mailList.descending') }}
            </v-btn>
          </div>

          <div class="mt-6 d-flex" v-if="!searchCondition.isAsc">
            <v-btn class="text-capitalize" @click="searchCondition.isAsc = true" variant="outlined" color="blue"
              width="100" height="30">{{ $t('word.mailList.ascending') }}
            </v-btn>

            <v-btn class="text-capitalize ml-3" variant="flat" color="blue" width="100" height="30">
              {{ $t('word.mailList.descending') }}
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card-text>


    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="!isFilter" class="btn-l text-capitalize" color="blue-darken-1" variant="flat"
        @click="handleClearClick">
        {{ $t('common.clear') }}
      </v-btn>

      <v-btn v-if="!isFilter" class="btn-l text-capitalize" color="blue-darken-1" variant="flat"
        @click="handleRegisterClick" :disabled="selectingPreset === undefined">
        {{ $t('word.settings.registration') }}
      </v-btn>

      <v-btn v-if="!isFilter" class="btn-l text-capitalize" color="blue-darken-1" variant="flat"
        @click="$emit('settingSearchCondition', searchCondition, selectingPreset)">
        {{ $t('common.search') }}
      </v-btn>

      <v-btn v-if="isFilter" class="btn-l text-capitalize" color="blue-darken-1" variant="flat"
        @click="$emit('settingFilterCondition', searchCondition)">
        {{ $t('common.settings') }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <v-dialog v-model="isCheckDialog" persistent width="600">
    <checkDialog :item="checkDialogItems" @yesCheck="handleOkClick" @cancelCheck="handleCancelClick" />
  </v-dialog>
</template>

<script setup lang="ts">
import { StoreWrapper } from '@/store/storeWrapper';
import { FilterSettingInfo, ManagerInfo, StatusInfo, CategoryInfo, PresetInfo } from '@/axios/mail/mailList/api';
import { INPUT_DATE_FORMAT, MAX_INPUT_LENGTH } from '@/constants/const';
import i18n from '@/constants/language';
import { computed, PropType, Ref, ref } from 'vue';
import { defineProps, defineEmits } from 'vue';
import DateAndTime from 'date-and-time';
import { CheckDialogItems } from '@/interfaces/Common';


// Storeラッパー
const store = StoreWrapper.getInstance();

const props = defineProps({
  /**
* 詳細検索かフィルタか識別
*/
  isFilter: {
    type: Boolean,
    required: true
  },
  /**
* 設定中のフィルタ設定情報
*/
  filterSettingInfo: {
    type: Object as PropType<FilterSettingInfo>,
    required: false
  },
  /**
* 設定中の詳細検索情報
*/
  searchSettingInfo: {
    type: Object as PropType<FilterSettingInfo>,
    required: false
  },
  /**
* プリセット情報リスト
*/
  presetInfoList: {
    type: Object as PropType<PresetInfo[]>,
    required: false
  },
  /**
* ステータスリスト
*/
  statusInfoList: {
    type: Array as PropType<StatusInfo[]>,
    required: true
  },
  /**
* 担当者リスト
*/
  managerInfoList: {
    type: Array as PropType<ManagerInfo[]>,
    required: true
  },
  /**
* 分類リスト
*/
  categoryInfoList: {
    type: Array as PropType<CategoryInfo[]>,
    required: true
  },
  /**
* ソート条件
*/
  sortCondition: {
    type: String,
    required: true
  },
})

const searchCondition = ref<FilterSettingInfo>({
  gameId: "",
  limit: 0,
  offset: 0,
  sendDateStart: null,
  sendDateEnd: null,
  inquiryId: null,
  caseId: null,
  userId: null,
  answerLimitList: [],
  statusIdList: [],
  categoryIdList: [],
  managerIdList: [],
  subject: null,
  mailBody: null,
  logDetail: null,
  comment: null,
  sort: props.sortCondition,
  isAsc: false
});

const emit = defineEmits(['postPresetInfo', 'closeDialog', 'settingSearchCondition', 'settingFilterCondition']);

const presets = [
  {
    presetKey: 1,
    presetValue: "Preset 1"
  },
  {
    presetKey: 2,
    presetValue: "Preset 2"
  },
  {
    presetKey: 3,
    presetValue: "Preset 3"
  },
  {
    presetKey: 4,
    presetValue: "Preset 4"
  },
  {
    presetKey: 5,
    presetValue: "Preset 5"
  }
]

interface SortMap {
  label: string,
  value: string
}

interface AnswerLimitMap {
  label: string,
  value: number
}

const checkDialogItems: Ref<CheckDialogItems> = ref<CheckDialogItems>({
  main: {
    message: "",
    visivle: false
  },
  sub: {
    message: "",
    visivle: false
  }
});

if (props.isFilter && props.filterSettingInfo) searchCondition.value = structuredClone(props.filterSettingInfo);
else if (props.searchSettingInfo) searchCondition.value = structuredClone(props.searchSettingInfo);

const mapArray = ref<SortMap[]>([
  { label: i18n.global.t("word.common.mailInfo.inquiryNo"), value: "inquiryId" },
  { label: i18n.global.t("word.common.mailInfo.caseId"), value: "caseId" },
  { label: i18n.global.t("word.common.mailInfo.userId"), value: "userId" },
  { label: i18n.global.t("word.common.mailInfo.subject"), value: "title" },
  { label: i18n.global.t("word.common.mailInfo.dateReceived"), value: "receptionDate" },
  { label: i18n.global.t("word.common.mailInfo.answerLimit"), value: "answerLimit" },
  { label: i18n.global.t("word.common.mailInfo.status"), value: "status" },
  { label: i18n.global.t("word.common.mailInfo.category"), value: "category" },
  { label: i18n.global.t("word.common.mailInfo.manager"), value: "manager" }
])

// 回答期限リスト
const answerLimitMap = ref<AnswerLimitMap[]>([])

answerLimitMap.value.push({ label: i18n.global.t("word.mailList.limit"), value: -1 });
answerLimitMap.value.push({ label: i18n.global.t("word.mailList.answer"), value: 0 });
for (let i = 1; i <= 24; i++)answerLimitMap.value.push({ label: i18n.global.t('word.mailList.inHour', { hour: i.toString() }), value: i });

const hoursArray = computed(() => {
  const arr = [];
  for (let i = 0; i < 24; i++) {
    arr.push({ text: i < 10 ? `0${i}` : i, value: i });
  }
  return arr;
});

const minutesArray = computed(() => {
  const arr = [];
  for (let i = 0; i < 60; i++) {
    arr.push({ text: i < 10 ? `0${i}` : i, value: i });
  }
  return arr;
});

const selectingPreset = ref<number | undefined>(store.getPresetId());
const isCheckDialog: Ref<boolean> = ref<boolean>(false);

/**
 * Dateをyyyy-MM-dd HH:mm:ssに変換する
 * @param tmpDate - 変換対象のDate
 */
const convertDateFormat = (tmpDate: Date) => {
  const year = tmpDate.getFullYear();
  const month = `00${tmpDate.getMonth() + 1}`.slice(-2);
  const date = `00${tmpDate.getDate()}`.slice(-2);
  const hours = `00${tmpDate.getHours()}`.slice(-2);
  const minutes = `00${tmpDate.getMinutes()}`.slice(-2);
  const seconds = '00';

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
}

/**
 * 受付日時（開始）編集
 * @param event - 入力したテキスト
 */
const handleSendDateStartInput = (event: any) => {
  if (event.target.value.length !== INPUT_DATE_FORMAT.length || !DateAndTime.isValid(event.target.value, INPUT_DATE_FORMAT)) {
    return;
  }

  searchCondition.value.sendDateStart = convertDateFormat(new Date(event.target.value));

  return;
}

/**
 * 受付日時（終了）編集
 * @param event - 入力したテキスト
 */
const handleSendDateEndInput = (event: any) => {

  if (event.target.value.length !== INPUT_DATE_FORMAT.length || !DateAndTime.isValid(event.target.value, INPUT_DATE_FORMAT)) return;

  searchCondition.value.sendDateEnd = convertDateFormat(new Date(event.target.value));

  return;
}

/**
 * ピッカーで選択した日付をを受付開始日時に反映する
 * @param tmpDate - ピッカーで選択した日付
 */
const sendDateStartDateClicked = (tmpDate: Date) => {
  const formattedDate = convertDateFormat(tmpDate);
  searchCondition.value.sendDateStart = formattedDate;

  return;
}

/**
* ピッカーで選択した日付をを受付終了日時に反映する
* @param tmpDate - ピッカーで選択した日付
*/
const sendDateEndDateClicked = (tmpDate: Date) => {

  const formattedDate = convertDateFormat(tmpDate);
  searchCondition.value.sendDateEnd = formattedDate;

  return;
}

/**
* ピッカーで選択した時間(Hour)を受付日時に反映する
* @param isSendDateStart - 受付開始日時フラグ
* @param eventHour - ピッカーで選択した時間
*/
const hourSelected = (isSendDateStart: boolean, eventHour: any) => {

  let tmpDate = null;
  const hour = eventHour.target.value;

  // 文字列からDate型に変更
  if (isSendDateStart && searchCondition.value.sendDateStart) tmpDate = new Date(searchCondition.value.sendDateStart);
  else if (!isSendDateStart && searchCondition.value.sendDateEnd) tmpDate = new Date(searchCondition.value.sendDateEnd);

  if (tmpDate) {
    const year = tmpDate.getFullYear();
    const month = `00${tmpDate.getMonth() + 1}`.slice(-2);
    const date = `00${tmpDate.getDate()}`.slice(-2);
    const hours = hour ? hour : `00${tmpDate.getHours()}`.slice(-2);
    const minutes = `00${tmpDate.getMinutes()}`.slice(-2);
    const seconds = '00';
    const formattedDate = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;

    if (isSendDateStart) searchCondition.value.sendDateStart = formattedDate;
    else searchCondition.value.sendDateEnd = formattedDate;
  }

  return;
}

/**
* ピッカーで選択した時間(Minute)を受付日時に反映する
* @param isSendDateStart - 受付開始日時フラグ
* @param eventMinute - ピッカーで選択した分
*/
const minuteSelected = (isSendDateStart: boolean, eventMinute: any) => {

  let tmpDate = null;
  const minute = eventMinute.target.value;

  // 文字列からDate型に変更
  if (isSendDateStart && searchCondition.value.sendDateStart) tmpDate = new Date(searchCondition.value.sendDateStart);
  else if (!isSendDateStart && searchCondition.value.sendDateEnd) tmpDate = new Date(searchCondition.value.sendDateEnd);

  if (tmpDate) {
    const year = tmpDate.getFullYear();
    const month = `00${tmpDate.getMonth() + 1}`.slice(-2);
    const date = `00${tmpDate.getDate()}`.slice(-2);
    const hours = `00${tmpDate.getHours()}`.slice(-2);
    const minutes = minute ? minute : `00${tmpDate.getMinutes()}`.slice(-2);
    const seconds = '00';
    const formattedDate = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;

    if (isSendDateStart) searchCondition.value.sendDateStart = formattedDate;
    else searchCondition.value.sendDateEnd = formattedDate;
  }

  return;
}

/**
 * プリセットボタン押下
 * @param msg -ダイアログで表示するメッセージ
 *   @returns {void}
 */
const handlePresetClick = () => {

  if (selectingPreset.value === undefined) {
    store.setPresetId(undefined);
    return;
  }

  checkDialogItems.value.main.message = i18n.global.t('NS00107', { selectingPreset: (selectingPreset.value).toString() });
  isCheckDialog.value = true;

  return;
}

// 詳細検索の検索条件を初期化する
const handleClearClick = () => {
  searchCondition.value = {
    gameId: "",
    limit: 0,
    offset: 0,
    sendDateStart: null,
    sendDateEnd: null,
    inquiryId: null,
    caseId: null,
    userId: null,
    answerLimitList: [],
    statusIdList: [],
    categoryIdList: [],
    managerIdList: [],
    subject: null,
    mailBody: null,
    logDetail: null,
    comment: null,
    sort: props.sortCondition,
    isAsc: false
  }

  return;
}

/**
 * 登録ボタン押下
 * @param msg -ダイアログで表示するメッセージ
 * @returns {void}
 */
const handleRegisterClick = () => {
  if (selectingPreset.value === null || selectingPreset.value === undefined) return;
  checkDialogItems.value.main.message = i18n.global.t('NS00108', { selectingPreset: (selectingPreset.value).toString() });
  isCheckDialog.value = true;

  return;
}

/**
 * OKボタン押下(確認ダイアログ)
 * @param msg -ダイアログで表示するメッセージ
 * @returns {void}
 */
const handleOkClick = () => {
  if (selectingPreset.value === null || selectingPreset.value === undefined) return;

  // 検索条件にプリセットの内容を反映する
  if (checkDialogItems.value.main.message === i18n.global.t('NS00107', { selectingPreset: (selectingPreset.value).toString() })) {
    if (!props.presetInfoList) return;

    const presetItems = props.presetInfoList[selectingPreset.value - 1].presetItems;

    searchCondition.value.sendDateStart = presetItems.find(item => item.presetKey === "sendDateStart")?.presetValue || null;
    searchCondition.value.sendDateEnd = presetItems.find(item => item.presetKey === "sendDateEnd")?.presetValue || null;
    searchCondition.value.inquiryId = presetItems.find(item => item.presetKey === "inquiryId")?.presetValue || null;
    searchCondition.value.userId = presetItems.find(item => item.presetKey === "userId")?.presetValue || null;
    searchCondition.value.caseId = presetItems.find(item => item.presetKey === "caseId")?.presetValue || null;
    searchCondition.value.answerLimitList = presetItems.find(item => item.presetKey === "answerLimitList")?.presetValue || [];
    searchCondition.value.statusIdList = presetItems.find(item => item.presetKey === "statusIdList")?.presetValue || [];
    searchCondition.value.categoryIdList = presetItems.find(item => item.presetKey === "categoryIdList")?.presetValue || [];
    searchCondition.value.managerIdList = presetItems.find(item => item.presetKey === "managerIdList")?.presetValue || [];
    searchCondition.value.subject = presetItems.find(item => item.presetKey === "subject")?.presetValue || null;
    searchCondition.value.mailBody = presetItems.find(item => item.presetKey === "mailBody")?.presetValue || null;
    searchCondition.value.logDetail = presetItems.find(item => item.presetKey === "logDetail")?.presetValue || null;
    searchCondition.value.comment = presetItems.find(item => item.presetKey === "comment")?.presetValue || null;

    isCheckDialog.value = false;
    return;
  }

  // プリセットを登録する
  const postPresetInfo: PresetInfo = {
    presetId: selectingPreset.value,
    presetItems: [
      { presetKey: 'sendDateStart', presetValue: searchCondition.value.sendDateStart || null },
      { presetKey: 'sendDateEnd', presetValue: searchCondition.value.sendDateEnd || null },
      { presetKey: 'inquiryId', presetValue: searchCondition.value.inquiryId ? searchCondition.value.inquiryId : null },
      { presetKey: 'caseId', presetValue: searchCondition.value.caseId || null },
      { presetKey: 'userId', presetValue: searchCondition.value.userId ? searchCondition.value.userId : null },
      { presetKey: 'answerLimitList', presetValue: searchCondition.value.answerLimitList || [] },
      { presetKey: 'statusIdList', presetValue: searchCondition.value.statusIdList || [] },
      { presetKey: 'categoryIdList', presetValue: searchCondition.value.categoryIdList || [] },
      { presetKey: 'managerIdList', presetValue: searchCondition.value.managerIdList || [] },
      { presetKey: 'subject', presetValue: searchCondition.value.subject || null },
      { presetKey: 'mailBody', presetValue: searchCondition.value.mailBody || null },
      { presetKey: 'logDetail', presetValue: searchCondition.value.logDetail || null },
      { presetKey: 'comment', presetValue: searchCondition.value.comment || null },
    ]
  }

  emit('postPresetInfo', postPresetInfo);
  isCheckDialog.value = false;
  return;
}

/**
 * キャンセルボタン押下（確認ダイアログ）
 * @param msg -ダイアログで表示するメッセージ
 * @returns {void}
 */
const handleCancelClick = () => {
  if (selectingPreset.value === null || selectingPreset.value === undefined) return;
  if (checkDialogItems.value.main.message === i18n.global.t('NS00107', { selectingPreset: (selectingPreset.value).toString() })) selectingPreset.value = undefined;

  isCheckDialog.value = false;

  return;
}
</script>


<style scoped>
.search-input {
  min-width: 730px;
}

.search-date {
  width: 250px;
  border: solid 1px rgb(151, 150, 150);
  border-radius: 4px;
}

.search-select {
  min-width: 150px;
  width: 150px;
}

.w360 {
  width: 360px;
}

.custom-time-picker-component {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-input {
  margin: 5px 3px;
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  border-color: var(--dp-border-color);
  outline: none;
  -webkit-appearance: menulist;
  appearance: button;
}

.v-select .v-field .v-text-field__prefix,
.v-select .v-field .v-text-field__suffix,
.v-select .v-field .v-field__input,
.v-select .v-field.v-field {
  cursor: pointer;
  background-color: #ffffff;
}

.v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  background-color: white;
}

::v-deep input.dp__input.dp__input_icon_pad {
  border: 1px solid rgb(153 153 153) !important;
}

::v-deep input.dp__input.dp__input_icon_pad:hover {
  border: 1px solid rgb(2, 2, 2) !important;
}

.clickable-text {
  color: #1976d2;
  cursor: pointer;
}

.chip {
  background-color: gray;
  margin-top: 0;
  margin-bottom: 0
}
</style>