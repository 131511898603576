import { createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode($setup["my_header"]),
      _withDirectives(_createVNode($setup["link_tab"], null, null, 512 /* NEED_PATCH */), [
        [_vShow, $setup.isAuthenticated]
      ]),
      _withDirectives(_createVNode(_component_router_view, null, null, 512 /* NEED_PATCH */), [
        [_vShow, $setup.isAuthenticated]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}