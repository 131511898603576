import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    color: "#333333",
    height: "40",
    style: {"min-width":"1900px"},
    absolute: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_tab, {
            id: "mail-value",
            color: "white",
            class: "text-capitalize",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push($setup.PAGE_INFO.MAIL.MAIL_LIST.PATH)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.mailList')) + " ", 1 /* TEXT */),
              _createElementVNode("a", null, [
                ($setup.store.getNotificationCount() !== 0)
                  ? (_openBlock(), _createBlock(_component_v_badge, {
                      key: 0,
                      id: "budge",
                      content: $setup.store.getNotificationCount(),
                      bottom: "",
                      color: "red",
                      "offset-x": "-20",
                      "offset-y": "-6"
                    }, null, 8 /* PROPS */, ["content"]))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _: 1 /* STABLE */
          }),
          ($setup.store.getAccountRole() !== $setup.ROLE_TYPE.ADMIN)
            ? (_openBlock(), _createBlock(_component_v_menu, {
                key: 0,
                id: "admin-menu",
                "open-on-click": ""
              }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_tab, _mergeProps({ class: "tab-color text-capitalize" }, props, { color: "white" }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.admin')) + " ▼ ", 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.adminLinkList, (adminLink, i) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, {
                          id: "admin-value",
                          key: i,
                          value: adminLink,
                          onClick: ($event: any) => (_ctx.$router.push(adminLink.url))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(adminLink.menuText), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onClick"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_v_menu, {
            id: "setiings-menu",
            "open-on-click": "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.store.setTargetAccountId($setup.store.getAccountId())))
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_tab, _mergeProps({ class: "tab-color text-capitalize" }, props, { color: "white" }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.settings')) + " ▼ ", 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.settingLinkList, (settingLink, i) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      id: "settings-value",
                      key: i,
                      value: settingLink,
                      onClick: ($event: any) => (_ctx.$router.push({ path: settingLink.url }))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(settingLink.menuText), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onClick"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_spacer)
    ]),
    _: 1 /* STABLE */
  }))
}