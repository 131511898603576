<template>
  <v-card class="d-flex">
    <h3 class="mt-5" style="text-align: center;">{{ $t('word.manageTemplate.templateSelect') }}</h3>
    <div class="main-div">
      <div class="page-left">
        <v-table class="select-template-table" hover>
          <thead>
            <tr>
              <th>{{ $t('word.manageTemplate.templateName') }}</th>
              <th>{{ $t('word.manageTemplate.templateContent') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in templateList" :key="item.templateId" ref="rows" @click="selectTemplate(index)">
              <td class="templateName">
                <p class="overFlow" style="max-width: 170px;">{{ item.templateName }}</p>
              </td>
              <td class="templateContent">
                <p class="overFlow" style="max-width: 210px;">{{ item.content }}</p>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>

      <div class="page-right">
        <div class="selected-template">
          <h4 class="text-left">{{ $t('word.manageTemplate.templateName') }}</h4>
          <v-text-field class="w-75" variant="outlined" bg-color="#e4e2e2" readonly>
            {{ templateList[index].templateName }}</v-text-field>
          <h4>{{ $t('word.manageTemplate.templateContent') }}</h4>
          <v-textarea variant="outlined" rows="10" bg-color="#e4e2e2" no-resize readonly
            :value="templateList[index].content">
          </v-textarea>
          <div class="d-flex w-100">
            <v-spacer></v-spacer>
            <v-btn class="btn-l text-capitalize" color="blue" variant="outlined" @click="$emit('cancelCheck')">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn class="btn-l text-capitalize" color="blue" variant="flat" @click="$emit('decisionTemplate', index)">
              {{ $t('common.select') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { TemplateInfo } from '@/axios/mail/mailSupport';
import { PropType, ref } from 'vue';
import { defineProps } from 'vue';

const props = defineProps({
  /**
* テンプレート情報リスト
*/
  templateList: {
    type: Array as PropType<TemplateInfo[]>,
    required: true
  }
})

props.templateList;

let rows = ref<any>();
let index = ref<number>(0);

/**
* テンプレート一覧のレコードを選択する
* @param num -選択したレコードの要素数
* @returns {void}
*/
const selectTemplate = (num: number): void => {
  rows.value[index.value].style = "";

  rows.value[num].style = "background-color: #CCC; font-weight: bold";

  index.value = num;

  return;
}
</script>


<style scoped>
.main-div {
  max-width: 1200px;
  min-width: 1200px;
  margin: 0px 50px 50px 50px;
  display: flex;
}

.page-left {
  width: 40%;
}

.page-right {
  width: 40%;
  margin-left: 20px;
}

.select-template-table {
  width: 100%;
  border: solid 2px #e4e2e2;
}

.selected-template {
  max-width: 500px;
  min-width: 500px;
  margin-left: 30px;
}

.templateName {
  width: 200px;
  text-align: left;
}

.templateContent {
  width: 250px;
  text-align: left;
}

.overFlow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(textarea:read-only) {
  cursor: default;
}

:deep(input:read-only) {
  font-size: small;
  cursor: default;
}

::v-deep th {
  color: #686767 !important;
  font-weight: bold !important;
}

::v-deep td {
  text-align: left;
  cursor: pointer;
}
</style>