/**
 * toolchopの表示秒数
 */
export const TOOLCHIP_DELAY_TIME = 700;

/**
 * snackbarのinfoメッセージが消えるまでの時間(デフォルト値)
 */
export const SNACKBAR_TIMEOUT_INF = 3000;

/**
 * snackbarのerrorメッセージが消えるまでの時間(デフォルト値)
 */
export const SNACKBAR_TIMEOUT_ERR = -1;

/**
 * レンダリング表示数
 */
export const RENDERING_CHUNKSIZE = 100;

/**
 * リクエストの回数上限
 */
export const REQUEST_COUNT_MAX = 3;
