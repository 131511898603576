import { Component } from 'vue';

/**
 * カラーコード
 */
export const COLOR = {
  /**
   * 赤
   */
  RED: '#ff7f7f',
  /**
   * グレー
   */
  READ_ONRY: '#e4e2e2',
};

/**
 * エクスポートの最大データ数
 */
export const MAX_EXPORT_NUM: number = 50000;

/**
 * StepFunctionsのタイムアウト時間(ms)
 */
export const STEP_FUNCTIONS_TIMEOUT: number = 900000;

/**
 * StepFunctionsの状態取得インターバル(ms)
 */
export const STEP_FUNCTIONS_INTERVAL: number = 2000;

/**
 * APIレスポンスのステータスコード
 */
export type statusCodeType = {
  /**
   * 正常
   */
  OK: number;
  /**
   * パラメータ異常
   */
  BAD_REQUEST: number;
  /**
   * アカウント認証エラー
   */
  UNAUTHORIZED: number;
  /**
   * システムエラー
   */
  INTERNAL_SERVER_ERROR: number;
  /**
   * タイムアウトエラー
   */
  GATEWAY_TIMEOUT: number;
};

/**
 * APIレスポンスのステータス番号
 */
export const statusCodes: statusCodeType = {
  /**
   * 正常
   */
  OK: 200,
  /**
   * パラメータ異常
   */
  BAD_REQUEST: 400,
  /**
   * アカウント認証エラー
   */
  UNAUTHORIZED: 401,
  /**
   * システムエラー
   */
  INTERNAL_SERVER_ERROR: 500,
  /**
   * タイムアウトエラー
   */
  GATEWAY_TIMEOUT: 504,
};

/**
 * 権限種別の型定義
 */
export type RoleType = {
  /**
   * 権限なし
   */
  NONE: number;
  /**
   * DeNA管理アカウント
   */
  ADMIN: number;
  /**
   * 管理カウント
   */
  PARTNER_ADMIN: number;
  /**
   * パートナー管理アカウント
   */
  PARTNER: number;
};

/**
 * 権限種別
 */
export const ROLE_TYPE: RoleType = {
  /**
   * 権限なし
   */
  NONE: -1,
  /**
   * DeNA管理アカウント
   */
  ADMIN: 1,
  /**
   * 管理アカウント
   */
  PARTNER_ADMIN: 2,
  /**
   * パートナー管理アカウント
   */
  PARTNER: 3,
};

/**
 * 権限名の型定義
 */
export type RoleName = {
  NONE: string;
  ADMIN: string;
  PARTNER_ADMIN: string;
  PARTNER: string;
};

/**
 * 権限名
 */
export const ROLE_NAME: RoleName = {
  /**
   * 権限なし
   */
  NONE: '',
  /**
   * DeNA管理アカウント
   */
  ADMIN: 'DeNA_Admin',
  /**
   * 管理アカウント
   */
  PARTNER_ADMIN: 'Admin',
  /**
   * パートナー管理アカウント
   */
  PARTNER: 'Operator',
};

/**
 * 言語の型定義
 */
export type LangueageType = {
  JN: number;
  EN: number;
  CH: number;
};

/**
 * 言語
 */
export const LANGUEAGE_TYPE: LangueageType = {
  JN: 0,
  EN: 1,
  CH: 2,
};

/**
 * メール詳細ページ：表示種別の型定義
 */
export type DisplayType = {
  /**
   * なし
   */
  NONE: string;
  /**
   * 案件ID
   */
  CASE_ID: string;
  /**
   * ユーザID
   */
  USER_ID: string;
};

/**
 * メール詳細ページ：表示種別
 */
export const DISPLAY_TYPE: DisplayType = {
  /**
   * なし
   */
  NONE: '',
  /**
   * 案件ID
   */
  CASE_ID: 'CASE_ID',
  /**
   * ユーザID
   */
  USER_ID: 'USER_ID',
};

/**
 * ページ情報の型定義
 */
export type PageInfo = {
  /**
   * パス
   */
  PATH: string;
  /**
   * ページ名
   */
  NAME: string;
  /**
   * 権限
   */
  ROLE: number[];
};

/**
 * ページ情報
 */
export const PAGE_INFO = {
  /**
   * メール系ページ
   */
  MAIL: {
    /** メール一覧ページ */
    MAIL_LIST: {
      PATH: '/Mail/mailList',
      NAME: 'mailList',
      ROLE: [ROLE_TYPE.ADMIN, ROLE_TYPE.PARTNER_ADMIN, ROLE_TYPE.PARTNER],
    } as PageInfo,
    /** メール詳細ページ */
    MAIL_DETAILS: {
      PATH: '/Mail/mailDetails',
      NAME: 'mailDetails',
      ROLE: [ROLE_TYPE.PARTNER_ADMIN, ROLE_TYPE.PARTNER],
    } as PageInfo,
    /** メール対応ページ */
    MAIL_SUPPORT: {
      PATH: '/Mail/mailSupport',
      NAME: 'mailSupport',
      ROLE: [ROLE_TYPE.PARTNER_ADMIN, ROLE_TYPE.PARTNER],
    } as PageInfo,
    /** 一括メール対応ページ */
    BULK_MAIL_SUPPORT: {
      PATH: '/Mail/bulkMailSupport',
      NAME: 'bulkMailSupport',
      ROLE: [ROLE_TYPE.PARTNER_ADMIN, ROLE_TYPE.PARTNER],
    } as PageInfo,
  },
  /**
   * 管理系ページ
   */
  ADMIN: {
    /** テンプレート管理ページ */
    MANAGE_TEMPLATE: {
      PATH: '/Admin/manageTemplate',
      NAME: 'manageTemplate',
      ROLE: [ROLE_TYPE.PARTNER_ADMIN, ROLE_TYPE.PARTNER],
    } as PageInfo,
    /** 分類管理ページ */
    MANAGE_CATEGORY: {
      PATH: '/Admin/manageCategory',
      NAME: 'manageCategory',
      ROLE: [ROLE_TYPE.PARTNER_ADMIN, ROLE_TYPE.PARTNER],
    } as PageInfo,
  },
  /**
   * 設定系ページ
   */
  SETTING: {
    /**
     * アカウント
     */
    ACCOUNT: {
      /** アカウント設定（管理）ページ */
      SETTING_ADMIN_ACCOUNT: {
        PATH: '/Setting/Account/settingAdminAccount',
        NAME: 'settingAdminAccount',
        ROLE: [ROLE_TYPE.ADMIN],
      } as PageInfo,
      /** アカウント一覧ページ */
      ACCOUNT_LIST: {
        PATH: '/Setting/Account/accountList',
        NAME: 'accountList',
        ROLE: [ROLE_TYPE.ADMIN],
      } as PageInfo,
      /** 管理アカウント作成ページ */
      CREATE_ADMIN_ACCOUNT: {
        PATH: '/Setting/Account/createAdminAccount',
        NAME: 'createAdminAccount',
        ROLE: [ROLE_TYPE.ADMIN],
      } as PageInfo,
      /** アカウント設定（パートナー）ページ */
      SETTING_PARTNER_ACCOUNT: {
        PATH: '/Setting/Account/settingPartnerAccount',
        NAME: 'settingPartnerAccount',
        ROLE: [ROLE_TYPE.ADMIN, ROLE_TYPE.PARTNER_ADMIN, ROLE_TYPE.PARTNER],
      } as PageInfo,
    },
    /**
     * ゲームタイトル
     */
    GAME_TITLE: {
      /** ゲームタイトル一覧ページ */
      GAME_TITLE_LIST: {
        PATH: '/setting/GameTitle/gameTitleList',
        NAME: 'gameTitleList',
        ROLE: [ROLE_TYPE.ADMIN, ROLE_TYPE.PARTNER_ADMIN],
      } as PageInfo,
      /** ゲームタイトル設定ページ */
      SETTING_GAME_TITLE: {
        PATH: '/setting/GameTitle/settingGameTitle',
        NAME: 'settingGameTitle',
        ROLE: [ROLE_TYPE.ADMIN, ROLE_TYPE.PARTNER_ADMIN],
      } as PageInfo,
    },
  },
};

/**
 * ページ情報リスト作成
 * @requires -pageInfoList ページ情報リスト
 */
const getPageInfoList = (): PageInfo[] => {
  const pageInfoList: PageInfo[] = new Array();
  // メール系
  for (const pageInfo of Object.values(PAGE_INFO.MAIL)) {
    pageInfoList.push(pageInfo);
  }

  // 管理系
  for (const pageInfo of Object.values(PAGE_INFO.ADMIN)) {
    pageInfoList.push(pageInfo);
  }

  // 設定系(アカウント)
  for (const pageInfo of Object.values(PAGE_INFO.SETTING.ACCOUNT)) {
    pageInfoList.push(pageInfo);
  }

  // 設定系(ゲームタイトル)
  for (const pageInfo of Object.values(PAGE_INFO.SETTING.GAME_TITLE)) {
    pageInfoList.push(pageInfo);
  }

  return pageInfoList;
};

/**
 * ページリスト
 */
export const PAGE_INFO_LIST: PageInfo[] = getPageInfoList();

/**
 * Route情報型定義
 */
export type RouteInfo = {
  /** ルートパス */
  path: string;
  /** ルート名 */
  name: string;
  /** 表示コンポーネント */
  component: Component;
  /** true : ログイン済み */
  isLogin: boolean;
};

/**
 * Route情報リストの型定義
 */
export type RouteInfoList = Array<RouteInfo>;

/**
 * Route情報リスト
 */
export const ROUTE_INFO_LIST: RouteInfoList = [
  /** Top */
  {
    path: '/',
    name: 'top',
    component: () => import('@/App.vue'),
    isLogin: false,
  },
  /** メール一覧ページ */
  {
    path: PAGE_INFO.MAIL.MAIL_LIST.PATH,
    name: PAGE_INFO.MAIL.MAIL_LIST.NAME,
    component: () => import('@/views/Mail/mailList.vue'),
    isLogin: true,
  },
  /** メール詳細ページ */
  {
    path: PAGE_INFO.MAIL.MAIL_DETAILS.PATH,
    name: PAGE_INFO.MAIL.MAIL_DETAILS.NAME,
    component: () => import('@/views/Mail/mailDetails.vue'),
    isLogin: true,
  },
  /** メール対応ページ */
  {
    path: PAGE_INFO.MAIL.MAIL_SUPPORT.PATH,
    name: PAGE_INFO.MAIL.MAIL_SUPPORT.NAME,
    component: () => import('@/views/Mail/mailSupport.vue'),
    isLogin: true,
  },
  /** メール一括対応ページ */
  {
    path: PAGE_INFO.MAIL.BULK_MAIL_SUPPORT.PATH,
    name: PAGE_INFO.MAIL.BULK_MAIL_SUPPORT.NAME,
    component: () => import('@/views/Mail/bulkMailSupport.vue'),
    isLogin: true,
  },
  /** テンプレート管理ページ */
  {
    path: PAGE_INFO.ADMIN.MANAGE_TEMPLATE.PATH,
    name: PAGE_INFO.ADMIN.MANAGE_TEMPLATE.NAME,
    component: () => import('@/views/Admin/manageTemplate.vue'),
    isLogin: true,
  },
  /** 分類管理ページ */
  {
    path: PAGE_INFO.ADMIN.MANAGE_CATEGORY.PATH,
    name: PAGE_INFO.ADMIN.MANAGE_CATEGORY.NAME,
    component: () => import('@/views/Admin/manageCategory.vue'),
    isLogin: true,
  },
  /** アカウント設定（管理）ページ */
  {
    path: PAGE_INFO.SETTING.ACCOUNT.SETTING_ADMIN_ACCOUNT.PATH,
    name: PAGE_INFO.SETTING.ACCOUNT.SETTING_ADMIN_ACCOUNT.NAME,
    component: () => import('@/views/Setting/Account/settingAdminAccount.vue'),
    isLogin: true,
  },
  /** アカウント一覧ページ */
  {
    path: PAGE_INFO.SETTING.ACCOUNT.ACCOUNT_LIST.PATH,
    name: PAGE_INFO.SETTING.ACCOUNT.ACCOUNT_LIST.NAME,
    component: () => import('@/views/Setting/Account/accountList.vue'),
    isLogin: true,
  },
  /** 管理アカウント作成ページ */
  {
    path: PAGE_INFO.SETTING.ACCOUNT.CREATE_ADMIN_ACCOUNT.PATH,
    name: PAGE_INFO.SETTING.ACCOUNT.CREATE_ADMIN_ACCOUNT.NAME,
    component: () => import('@/views/Setting/Account/createAdminAccount.vue'),
    isLogin: true,
  },
  /** アカウント設定（パートナー）ページ */
  {
    path: PAGE_INFO.SETTING.ACCOUNT.SETTING_PARTNER_ACCOUNT.PATH,
    name: PAGE_INFO.SETTING.ACCOUNT.SETTING_PARTNER_ACCOUNT.NAME,
    component: () => import('@/views/Setting/Account/settingPartnerAccount.vue'),
    isLogin: true,
  },
  /** ゲームタイトル一覧ページ */
  {
    path: PAGE_INFO.SETTING.GAME_TITLE.GAME_TITLE_LIST.PATH,
    name: PAGE_INFO.SETTING.GAME_TITLE.GAME_TITLE_LIST.NAME,
    component: () => import('@/views/Setting/GameTitle/gameTitleList.vue'),
    isLogin: true,
  },
  /** ゲームタイトル設定ページ */
  {
    path: PAGE_INFO.SETTING.GAME_TITLE.SETTING_GAME_TITLE.PATH,
    name: PAGE_INFO.SETTING.GAME_TITLE.SETTING_GAME_TITLE.NAME,
    component: () => import('@/views/Setting/GameTitle/settingGameTitle.vue'),
    isLogin: true,
  },
];

/**
 * 全ページのパスリストを取得
 * @returns {string[]} ページパスリスト
 */
const getPagePathList = (routeInfoList: RouteInfoList): string[] => {
  const pagePathList: string[] = new Array();

  for (const routeInfo of routeInfoList) {
    if (routeInfo.name !== 'health' && routeInfo.name !== 'top') pagePathList.push(routeInfo.path);
  }

  return pagePathList;
};

/**
 * ページのパスリスト
 */
export const PAGE_PATH_LIST: string[] = getPagePathList(ROUTE_INFO_LIST);

/**
 * 入力文字制限の型定義
 */
export type MaxInputLength = {
  /** 問い合わせID */
  INQUIRY_ID: number;
  /** 案件ID */
  CASE_ID: number;
  /** ユーザID */
  USER_ID: number;
  /** 件名 */
  SUBJECT: number;
  /** 本文（フィルタ・詳細検索ダイアログ） */
  MAIL_BODY_SEARCH_FILTER: number;
  /** ログ（フィルタ・詳細検索ダイアログ） */
  LOG_DETAIL_SEARCH_FILTER: number;
  /** フリーコメント（フィルタ・詳細検索ダイアログ） */
  FREE_COMMENT_SEARCH_FILTER: number;
  /** テンプレート名 */
  TEMPLATE_NAME: number;
  /** 分類 */
  CATEGORY: number;
  /** アカウントID */
  ACCOUNT_ID: number;
  /** 表示名 */
  DISPLAY_NAME: number;
  /** 会社名 */
  COMPANY_NAME: number;
  /** メールアドレス */
  MAIL_ADDRESS: number;
  /** slack webhook URL */
  SLACK_URL: number;
  /**送信禁止文字 */
  PROHIBIT_WORD: number;
  /** 除外ワード */
  EXCLUSION_WORD: number;
};

/**
 * 入力文字制限の最大文字数
 */
export const MAX_INPUT_LENGTH: MaxInputLength = {
  /** 問い合わせID */
  INQUIRY_ID: 10,
  /** 案件ID */
  CASE_ID: 10,
  /** ユーザID */
  USER_ID: 9,
  /** 件名 */
  SUBJECT: 96,
  /** 本文（フィルタ・詳細検索ダイアログ） */
  MAIL_BODY_SEARCH_FILTER: 512,
  /** ログ（フィルタ・詳細検索ダイアログ） */
  LOG_DETAIL_SEARCH_FILTER: 512,
  /** フリーコメント（フィルタ・詳細検索ダイアログ） */
  FREE_COMMENT_SEARCH_FILTER: 512,
  /** テンプレート名 */
  TEMPLATE_NAME: 96,
  /** 分類 */
  CATEGORY: 96,
  /** アカウントID */
  ACCOUNT_ID: 32,
  /** 表示名 */
  DISPLAY_NAME: 64,
  /** 会社名 */
  COMPANY_NAME: 96,
  /** メールアドレス */
  MAIL_ADDRESS: 96,
  /** slack webhook URL */
  SLACK_URL: 96,
  /** 送信禁止文字 */
  PROHIBIT_WORD: 96,
  /** 除外ワード */
  EXCLUSION_WORD: 96,
};

/**
 * スナックバーのメッセージ種別
 */
export const SNACKBAR_TYPE = {
  INFO: 'info',
  ERROR: 'error',
} as const;

/**
 * 受付日時のフォーマット
 */
export const INPUT_DATE_FORMAT: string = 'YYYY-MM-DD HH:mm';
