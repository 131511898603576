<template>
  <v-app-bar style="min-width: 1900px; background-color: black" absolute>
    <div class="headerImage">
      <img style="width: 270px;" id="system-img" src="@/assets/DMS_title.png"
        @click="$router.push(PAGE_INFO.MAIL.MAIL_LIST.PATH)">
    </div>

    <v-spacer></v-spacer>
    <div v-if="isAuthenticated" class="d-flex">
      <div class="d-flex">
        <div class="box"><a class="pt-1 header-text">{{ store.getRoleName() }} </a></div>

        <a class="pt-3 ml-2 header-text">[ {{ store.getCompanyName() }} ]</a>

        <a class="pt-3 ml-2 header-text"> {{ store.getAccountName() }}</a>

        <v-btn variant="outlined" class="logoutBtn text-capitalize" @click="openDialog($t('NS00001'))">
          {{ $t('common.logout') }}
        </v-btn>
      </div>

      <div class="languageSelect">
        <v-select :items="langaegeInfoList" v-model="language" item-title="languageValue" item-value="languageId"
          class="mr-4 custom-select" prepend-inner-icon="mdi-web" variant="outlined" density="compact" hide-details
          @update:model-value="changeLang">
        </v-select>
      </div>
    </div>
  </v-app-bar>

  <v-dialog v-model="isDialog" persistent width="500">
    <checkDialog :item="checkDialogItems" @yesCheck="execLogout" @cancelCheck="isDialog = false" />
  </v-dialog>
</template>

<script setup lang="ts">
import i18n from '@/constants/language';
import { StoreWrapper } from '@/store/storeWrapper';
import { LANGUEAGE_TYPE, PAGE_INFO, statusCodes } from '@/constants/const';
import { Ref, ref, watchEffect } from 'vue';
import { useAuth0 } from "@auth0/auth0-vue";
import { PostParamChangeLanguageAccount } from '@/axios/webapp/account/api';
import { changeLanguageAccountApi } from '@/api/webapp/account/api';
import { createRequestConfig } from '@/api/utility';
import { CheckDialogItems } from '@/interfaces/Common';

const store = StoreWrapper.getInstance();

// Auth0のAPIモジュール
const { logout, isLoading, getAccessTokenSilently, user, isAuthenticated } = useAuth0();

const langaegeInfoList = [
  {
    languageId: LANGUEAGE_TYPE.JN,
    languageValue: '日本語',
  },
  {
    languageId: LANGUEAGE_TYPE.EN,
    languageValue: 'English',
  },
  {
    languageId: LANGUEAGE_TYPE.CH,
    languageValue: '中文',
  }]
  ;

const checkDialogItems: Ref<CheckDialogItems> = ref<CheckDialogItems>({
  main: {
    message: "",
    visivle: false
  },
  sub: {
    message: "",
    visivle: false
  }
});

const language = ref<number>(LANGUEAGE_TYPE.JN);
const isDialog = ref<boolean>(false);
const isInit = ref<boolean>(false);

/**
 * Auth0から取得した言語情報をもとに、表示言語の設定をする
 * @returns {void}
 */
watchEffect(() => {
  // Auth0のロード完了まで待つ
  if (isLoading.value) return;

  // Auth0のロード完了
  if (!isInit.value || !store.isAuthenticated()) {
    isInit.value = true;

    // Auth0のロード完了後の処理
    language.value = user.value?.metadata.language;

    if (language.value === LANGUEAGE_TYPE.JN) {
      i18n.global.locale = "ja";
    } else if (language.value === LANGUEAGE_TYPE.EN) {
      i18n.global.locale = "en";
    } else if (language.value === LANGUEAGE_TYPE.CH) {
      i18n.global.locale = "ch";
    }

    return;
  }
})

/**
 * 表示言語を切り替える
 * @returns {void}
 */
const changeLang = async (): Promise<void> => {
  if (language.value === LANGUEAGE_TYPE.JN) {
    i18n.global.locale = "ja";
  } else if (language.value === LANGUEAGE_TYPE.EN) {
    i18n.global.locale = "en";
  } else if (language.value === LANGUEAGE_TYPE.CH) {
    i18n.global.locale = "ch";
  }

  await changeLanguageAccount();
  return;
}

/**
 * 表示言語を切り替える
 * @returns {void}
 */
const changeLanguageAccount = async (): Promise<void> => {
  const postParam: PostParamChangeLanguageAccount =
  {
    language: language.value,
    loggedInAccountId: store.getAccountId(),
  };

  try {
    const result = await changeLanguageAccountApi.changeLanguageAccount(postParam, createRequestConfig(await getAccessTokenSilently()));

    // レスポンスが異常な場合、システムエラーとして例外をスロー
    if (!result.data || !result.data.data || result.data.status !== 'success') return;

    const response = result.data.data;

    store.setCount(response.unsupportedCount);
    store.setAccountName(response.accountName);

  } catch (error: any) {
    // アカウント認証エラー(ログインページにリダイレクト)
    if (error.response.status === statusCodes.UNAUTHORIZED) return store.setIsAccountAuthError(true);
  }

  return;
}

/**
 * 確認ダイアログを開く
 * @param msg -ダイアログで表示するメッセージ
 * @returns {void}
 */
const openDialog = (msg: string) => {

  checkDialogItems.value.main.message = msg;
  isDialog.value = true;

  return;
}

/**
 * ログアウトする
 * @returns {void}
 */
const execLogout = async (): Promise<void> => {

  isDialog.value = false;
  store.clearStoreData();
  await logout({ logoutParams: { returnTo: window.location.origin } });

  return;
}
</script>

<style scoped>
.header-flame {
  min-width: 1280px;
}

.headerImage {
  margin-left: 12px;
  margin-top: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.box {
  width: 120px;
  height: 30px;
  margin-top: 5px;
  padding-top: 3px;
  border: 1px solid rgb(235, 235, 123);
  border-radius: 5px;
}

.header-text {
  color: rgb(255, 255, 255);
  font-size: 15px;
}

.logoutBtn {
  width: 140px;
  height: 36px;
  padding-top: 1px;
  margin-top: 3px;
  margin-left: 16px;
  margin-right: 16px;
  color: rgb(255, 255, 255);
}

.languageSelect {
  width: 180px;
  height: 40px;
  margin-top: 1px;
  color: white;
}
</style>
