import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-3 ml-3" }
const _hoisted_2 = { class: "text-h5" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  class: "mt-5",
  style: {"max-width":"400px","min-width":"400px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('word.mailSupport.mailSendingResult')), 1 /* TEXT */)
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { class: "justify-start pb-0" }, {
            default: _withCtx(() => [
              (!$props.sendMailInfo.isError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('NS00302')), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              ($props.sendMailInfo.isError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t($props.sendMailInfo.message)), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_v_list, {
                  density: "compact",
                  "max-height": "200"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_subheader, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('word.common.mailInfo.caseId')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.caseIdList, (item, i) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: i,
                        value: item
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("・" + _toDisplayString(item), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            class: "btn-l",
            color: "blue-darken-1",
            variant: "flat",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sendMailComplete')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.ok')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}