import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17d518e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mt-5",
  style: {"text-align":"center"}
}
const _hoisted_2 = { class: "main-div" }
const _hoisted_3 = { class: "page-left" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "templateName" }
const _hoisted_6 = {
  class: "overFlow",
  style: {"max-width":"170px"}
}
const _hoisted_7 = { class: "templateContent" }
const _hoisted_8 = {
  class: "overFlow",
  style: {"max-width":"210px"}
}
const _hoisted_9 = { class: "page-right" }
const _hoisted_10 = { class: "selected-template" }
const _hoisted_11 = { class: "text-left" }
const _hoisted_12 = { class: "d-flex w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "d-flex" }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('word.manageTemplate.templateSelect')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_table, {
            class: "select-template-table",
            hover: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('word.manageTemplate.templateName')), 1 /* TEXT */),
                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('word.manageTemplate.templateContent')), 1 /* TEXT */)
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.templateList, (item, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: item.templateId,
                    ref_for: true,
                    ref: "rows",
                    onClick: ($event: any) => ($setup.selectTemplate(index))
                  }, [
                    _createElementVNode("td", _hoisted_5, [
                      _createElementVNode("p", _hoisted_6, _toDisplayString(item.templateName), 1 /* TEXT */)
                    ]),
                    _createElementVNode("td", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(item.content), 1 /* TEXT */)
                    ])
                  ], 8 /* PROPS */, _hoisted_4))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.$t('word.manageTemplate.templateName')), 1 /* TEXT */),
            _createVNode(_component_v_text_field, {
              class: "w-75",
              variant: "outlined",
              "bg-color": "#e4e2e2",
              readonly: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.templateList[$setup.index].templateName), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('word.manageTemplate.templateContent')), 1 /* TEXT */),
            _createVNode(_component_v_textarea, {
              variant: "outlined",
              rows: "10",
              "bg-color": "#e4e2e2",
              "no-resize": "",
              readonly: "",
              value: $props.templateList[$setup.index].content
            }, null, 8 /* PROPS */, ["value"]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                class: "btn-l text-capitalize",
                color: "blue",
                variant: "outlined",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancelCheck')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_btn, {
                class: "btn-l text-capitalize",
                color: "blue",
                variant: "flat",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('decisionTemplate', $setup.index)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.select')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}