import { createApp } from "vue";
import _ from 'lodash';
import App from "@/App.vue";
import router from "@/router";
import { Store } from "@/store/store";
import { StoreWrapper } from "@/store/storeWrapper";
import i18n from "@/constants/language";
import checkDialog from "@/components/checkDialog.vue";
import selectTemplateDialog from "@/components/selectTemplateDialog.vue";
import searchFilterDialog from "@/components/searchFilterDialog.vue";
import bulkActionDialog from "@/components/bulkActionDialog.vue";
import mailSendConfirmationDialog from "@/components/mailSendConfirmationDialog.vue";

// Auth0モジュール
import { createAuth0 } from '@auth0/auth0-vue';

// Vuetify のインポートを追加
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

// DatePicker
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const vuetify = createVuetify({
  components,
  directives,
});

const auth0Plugin = createAuth0({
  domain: process.env.VUE_APP_AUTH_DOMAIN ? process.env.VUE_APP_AUTH_DOMAIN : '',
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID ? process.env.VUE_APP_AUTH_CLIENT_ID : '',
  authorizationParams: {
    redirect_uri: `${window.location.origin}${process.env.VUE_APP_AUTH_REDIRECT_URL ? process.env.VUE_APP_AUTH_REDIRECT_URL : ''}`,
    audience: process.env.VUE_APP_AUTH_AUDIENCE ? process.env.VUE_APP_AUTH_AUDIENCE : ''
  }  
});

const store = StoreWrapper.getInstance(Store.getInstance().getStore());

// 環境変数からトライアルゲームIDを取得
const envTrialGameId: string = process.env.VUE_APP_TRIAL_GAME_ID ?? '';
const trialGameIdList: string[] = new Array();
if (!_.isEmpty(envTrialGameId)) {
  for (const trialGameId of envTrialGameId.split(',')) {
    trialGameIdList.push(trialGameId);
  }
}

// トライアルゲームIDをstoreにセット
store.setTrialGameIdList(trialGameIdList);

// App作成
const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(store.getStore());
app.use(i18n);
app.use(auth0Plugin);
app.component("VueDatePicker", VueDatePicker);
app.component("checkDialog", checkDialog);
app.component("selectTemplateDialog", selectTemplateDialog);
app.component("searchFilterDialog", searchFilterDialog);
app.component("bulkActionDialog", bulkActionDialog);
app.component("mailSendConfirmationDialog", mailSendConfirmationDialog);
app.mount("#app");
