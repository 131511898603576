import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { ROLE_TYPE, ROLE_NAME, DISPLAY_TYPE } from '@/constants/const';
import { BulkMailSupportStoreInfo, MailDetailsStoreInfo, MailInfoList, MailSupportStoreInfo } from '@/interfaces/Pages';
import { CategoryInfo, FilterSettingInfo, GetMailOption, ManagerInfo, PresetInfo, StatusInfo } from '@/axios/mail/mailList/api';
import { GameTitleInfoList } from '@/interfaces/Common';

/**
 * アカウント情報の型定義
 */
export type AccountInfo = {
  /** 認証フラグ */
  isAuthenticated: boolean;
  /** アカウントID */
  id: string;
  /** メールアドレス */
  email: string;
  /** 権限（デフォルト: -1, 管理: 0, パートナー管理: 1, パートナー: 2） */
  role: number;
  /** 特定ゲーム紐付きフラグ */
  isLinkedCygames: boolean;
};

/**
 * 詳細検索情報の型定義
 */
export type SearchCondition = {
  /** 詳細検索フラグ */
  isDetailedSearch: boolean;
  /** ページネーションのページ数 */
  pageNum: number;
  /** ゲーム情報リスト */
  gameTitleInfoList: GameTitleInfoList[];
  /** メール情報リスト */
  mailInfoList: MailInfoList[];
  /** ステータスリスト */
  statusList: StatusInfo[];
  /** 分類リスト */
  categoryList: CategoryInfo[];
  /** 担当者リスト */
  managerList: ManagerInfo[];
  /** メールオプション */
  mailOption: GetMailOption;
  /** フィルタ条件 */
  filterSettingInfo: FilterSettingInfo;
  /** 詳細検索条件 */
  searchSettingInfo: FilterSettingInfo;
  /** 詳細検索条件 */
  presetInfoList: PresetInfo[];
};

export class Store {
  // シングルトン対応
  private static instance: Store;

  // メンバ変数
  private store: any;

  // アカウント情報（デフォルト）
  private defaultAccountInfo: AccountInfo = {
    isAuthenticated: false,
    id: '',
    email: '',
    role: ROLE_TYPE.NONE,
    isLinkedCygames: false
  };

  // 詳細検索情報（デフォルト）
  private defaultSearchCondition: SearchCondition = {
    isDetailedSearch: false,
    pageNum: 0,
    gameTitleInfoList: new Array(),
    mailInfoList: new Array(),
    statusList: new Array(),
    categoryList: new Array(),
    managerList: new Array(),
    mailOption: {
      limit: 0,
      offset: 0,
      mailCount: 0,
    },
    filterSettingInfo: {
      gameId: '',
      limit: 0,
      offset: 0,
      sendDateStart: null,
      sendDateEnd: null,
      inquiryId: null,
      caseId: null,
      userId: null,
      answerLimitList: [],
      statusIdList: [],
      categoryIdList: [],
      managerIdList: [],
      subject: null,
      mailBody: null,
      logDetail: null,
      comment: null,
      sort: '',
      isAsc: false,
    },
    searchSettingInfo: {
      gameId: '',
      limit: 0,
      offset: 0,
      sendDateStart: null,
      sendDateEnd: null,
      inquiryId: null,
      caseId: null,
      userId: null,
      answerLimitList: [],
      statusIdList: [],
      categoryIdList: [],
      managerIdList: [],
      subject: null,
      mailBody: null,
      logDetail: null,
      comment: null,
      sort: '',
      isAsc: false,
    },
    presetInfoList: [
      {
        presetId: 1,
        presetItems: [
          { presetKey: 'sendDateStart', presetValue: null },
          { presetKey: 'sendDateEnd', presetValue: null },
          { presetKey: 'inquiryId', presetValue: null },
          { presetKey: 'caseId', presetValue: null },
          { presetKey: 'userId', presetValue: null },
          { presetKey: 'answerLimitList', presetValue: [] },
          { presetKey: 'statusIdList', presetValue: [] },
          { presetKey: 'categoryIdList', presetValue: [] },
          { presetKey: 'managerIdList', presetValue: [] },
          { presetKey: 'subject', presetValue: null },
          { presetKey: 'mailBody', presetValue: null },
          { presetKey: 'logDetail', presetValue: null },
          { presetKey: 'comment', presetValue: null },
        ],
      },
      {
        presetId: 2,
        presetItems: [
          { presetKey: 'sendDateStart', presetValue: null },
          { presetKey: 'sendDateEnd', presetValue: null },
          { presetKey: 'inquiryId', presetValue: null },
          { presetKey: 'caseId', presetValue: null },
          { presetKey: 'userId', presetValue: null },
          { presetKey: 'answerLimitList', presetValue: [] },
          { presetKey: 'statusIdList', presetValue: [] },
          { presetKey: 'categoryIdList', presetValue: [] },
          { presetKey: 'managerIdList', presetValue: [] },
          { presetKey: 'subject', presetValue: null },
          { presetKey: 'mailBody', presetValue: null },
          { presetKey: 'logDetail', presetValue: null },
          { presetKey: 'comment', presetValue: null },
        ],
      },
      {
        presetId: 3,
        presetItems: [
          { presetKey: 'sendDateStart', presetValue: null },
          { presetKey: 'sendDateEnd', presetValue: null },
          { presetKey: 'inquiryId', presetValue: null },
          { presetKey: 'caseId', presetValue: null },
          { presetKey: 'userId', presetValue: null },
          { presetKey: 'answerLimitList', presetValue: [] },
          { presetKey: 'statusIdList', presetValue: [] },
          { presetKey: 'categoryIdList', presetValue: [] },
          { presetKey: 'managerIdList', presetValue: [] },
          { presetKey: 'subject', presetValue: null },
          { presetKey: 'mailBody', presetValue: null },
          { presetKey: 'logDetail', presetValue: null },
          { presetKey: 'comment', presetValue: null },
        ],
      },
      {
        presetId: 4,
        presetItems: [
          { presetKey: 'sendDateStart', presetValue: null },
          { presetKey: 'sendDateEnd', presetValue: null },
          { presetKey: 'inquiryId', presetValue: null },
          { presetKey: 'caseId', presetValue: null },
          { presetKey: 'userId', presetValue: null },
          { presetKey: 'answerLimitList', presetValue: [] },
          { presetKey: 'statusIdList', presetValue: [] },
          { presetKey: 'categoryIdList', presetValue: [] },
          { presetKey: 'managerIdList', presetValue: [] },
          { presetKey: 'subject', presetValue: null },
          { presetKey: 'mailBody', presetValue: null },
          { presetKey: 'logDetail', presetValue: null },
          { presetKey: 'comment', presetValue: null },
        ],
      },
      {
        presetId: 5,
        presetItems: [
          { presetKey: 'sendDateStart', presetValue: null },
          { presetKey: 'sendDateEnd', presetValue: null },
          { presetKey: 'inquiryId', presetValue: null },
          { presetKey: 'caseId', presetValue: null },
          { presetKey: 'userId', presetValue: null },
          { presetKey: 'answerLimitList', presetValue: [] },
          { presetKey: 'statusIdList', presetValue: [] },
          { presetKey: 'categoryIdList', presetValue: [] },
          { presetKey: 'managerIdList', presetValue: [] },
          { presetKey: 'subject', presetValue: null },
          { presetKey: 'mailBody', presetValue: null },
          { presetKey: 'logDetail', presetValue: null },
          { presetKey: 'comment', presetValue: null },
        ],
      },
    ],
  };

  // メール詳細情報（デフォルト）
  private defaultMailDetailsInfo: MailDetailsStoreInfo = {
    displayType: DISPLAY_TYPE.NONE,
    caseId: '',
    userId: '',
    mailId: 0,
    inquiryId: '',
    gameId: '',
    gameTitle: '',
    statusInfoList: new Array(),
    categoryInfoList: new Array(),
    managerInfoList: new Array(),
  };

  // メール対応情報（デフォルト）
  private defaultMailSupportInfo: MailSupportStoreInfo = {
    displayType: DISPLAY_TYPE.NONE,
    caseId: '',
    userId: '',
    mailId: 0,
    inquiryId: '',
    gameId: '',
    gameTitle: '',
  };

  // メール一括対応情報（デフォルト）
  private defaultBulkMailSupportInfo: BulkMailSupportStoreInfo = {
    mailIdList: new Array(),
    caseIdList: new Array(),
    gameId: '',
    gameTitle: '',
  };

  // コンストラクタ
  private constructor() {
    this.initialize();
  }

  static getInstance() {
    if (!Store.instance) Store.instance = new Store();

    return Store.instance;
  }

  public getStore() {
    return this.store;
  }

  private initialize() {
    const that = this;

    this.store = createStore({
      plugins: [createPersistedState()],
      state() {
        return {
          // ページ名
          pageName: '' as string,
          // トライアルゲームIDリスト
          trialGameIdList: new Array() as string[],
          // アカウント情報
          accountInfo: that.defaultAccountInfo,
          // アカウント種別名
          roleName: ROLE_NAME.NONE as string,
          // アカウント名
          accountName: '' as string,
          // アクセストークン
          token: '' as string,
          // 会社名
          companyName: '' as string,
          // 未対応件数
          notificationCount: 0 as number,
          // ゲームID
          gameId: '' as string,
          // ゲームタイトル
          gameTitle: '' as string,
          // 設定対象のゲームID
          settingsGameId: '' as string,
          // 問い合わせID
          inquiryId: '' as string,
          // メールID
          mailId: 0 as number,
          // 選択中のメールID
          selectedMailId: 0 as number,
          // 設定対象アカウントID
          targetAccountId: '' as string,
          // ページ遷移確認フラグ
          isAlert: false as boolean,
          // アカウント認証エラーフラグ
          isAccountAuthError: false as boolean,
          // 新規タブ設定フラグ
          isNewTab: false as boolean,
          // プリセット番号
          presetId: undefined as boolean | undefined,
          // メール一覧：詳細検索条件
          searchCondition: that.defaultSearchCondition,
          // メール詳細情報
          mailDetailsInfo: that.defaultMailDetailsInfo,
          // メール対応情報
          mailSupportInfo: that.defaultMailSupportInfo,
          // メール一括対応情報
          bulkMailSupportInfo: that.defaultBulkMailSupportInfo,
        };
      },
      getters: {
        // ページ名の取得
        pageName(state) {
          return state.pageName;
        },
        // トライアルゲームIDリストの取得
        trialGameIdList(state) {
          return state.trialGameIdList;
        },
        // アカウント情報の取得
        accountInfo(state) {
          return {
            isAuthenticated: state.accountInfo.isAuthenticated,
            id: state.accountInfo.id,
            email: state.accountInfo.email,
            role: state.accountInfo.role,
            isLinkedCygames: state.accountInfo.isLinkedCygames
          };
        },
        // アカウント種別名
        roleName(state) {
          return state.roleName;
        },
        // アカウント名
        accountName(state) {
          return state.accountName;
        },
        // アクセストークン
        token(state) {
          return state.token;
        },
        // 会社名
        companyName(state) {
          return state.companyName;
        },
        // ゲームID
        gameId(state) {
          return state.gameId;
        },
        // ゲームタイトル
        gameTitle(state) {
          return state.gameTitle;
        },
        // 設定対象のゲームID
        settingsGameId(state) {
          return state.settingsGameId;
        },
        // 問い合わせID
        inquiryId(state) {
          return state.inquiryId;
        },
        // メールID
        mailId(state) {
          return state.mailId;
        },
        // 選択中のメールID
        selectedMailId(state) {
          return state.selectedMailId;
        },
        // 設定対象アカウントId
        targetAccountId(state) {
          return state.targetAccountId;
        },
        // アラートの有無
        isAlert(state) {
          return state.isAlert;
        },
        // アカウント認証エラーフラグ
        isAccountAuthError(state) {
          return state.isAccountAuthError;
        },
        // 新規タブ設定フラグ
        isNewTab(state) {
          return state.isNewTab;
        },
        // プリセット番号
        presetId(state) {
          return state.presetId;
        },
        // 未対応件数の取得
        notificationCount(state) {
          return state.notificationCount;
        },
        // メール一覧：詳細検索情報の取得
        searchCondition(state) {
          return state.searchCondition;
        },
        // メール詳細情報の取得
        mailDetailsInfo(state) {
          return {
            displayType: state.mailDetailsInfo.displayType,
            caseId: state.mailDetailsInfo.caseId,
            userId: state.mailDetailsInfo.userId,
            mailId: state.mailDetailsInfo.mailId,
            inquiryId: state.mailDetailsInfo.inquiryId,
            gameId: state.mailDetailsInfo.gameId,
            gameTitle: state.mailDetailsInfo.gameTitle,
            statusInfoList: state.mailDetailsInfo.statusInfoList,
            categoryInfoList: state.mailDetailsInfo.categoryInfoList,
            managerInfoList: state.mailDetailsInfo.managerInfoList,
          };
        },
        // メール対応情報の取得
        mailSupportInfo(state) {
          return {
            displayType: state.mailSupportInfo.displayType,
            caseId: state.mailSupportInfo.caseId,
            userId: state.mailSupportInfo.userId,
            mailId: state.mailSupportInfo.mailId,
            inquiryId: state.mailSupportInfo.inquiryId,
            gameId: state.mailSupportInfo.gameId,
            gameTitle: state.mailSupportInfo.gameTitle,
          };
        },
        // メール詳細情報の取得
        bulkMailSupportInfo(state) {
          return {
            caseIdList: state.bulkMailSupportInfo.caseIdList,
            mailIdList: state.bulkMailSupportInfo.mailIdList,
            gameId: state.bulkMailSupportInfo.gameId,
            gameTitle: state.bulkMailSupportInfo.gameTitle,
          };
        },
      },
      mutations: {
        setPageName(state: any, value: string) {
          state.pageName = value;
        },
        setTrialGameIdList(state: any, value: string[]) {
          state.trialGameIdList = value;
        },
        setAccountInfo(state: any, value: AccountInfo) {
          state.accountInfo = value;
        },
        setIsAuthenticated(state: any, value: boolean) {
          state.accountInfo.isAuthenticated = value;
        },
        setAccountId(state: any, value: string) {
          state.accountInfo.id = value;
        },
        setAccountEmail(state: any, value: string) {
          state.accountInfo.email = value;
        },
        setAccountRole(state: any, value: number) {
          state.accountInfo.role = value;
        },
        setIsLinkedCygames(state: any, value: boolean) {
          state.accountInfo.isLinkedCygames = value;
        },
        setRoleName(state: any, value: string) {
          state.roleName = value;
        },
        setAccountName(state: any, value: string) {
          state.accountName = value;
        },
        setToken(state: any, value: string) {
          state.token = value;
        },
        setCompanyName(state: any, value: string) {
          state.companyName = value;
        },
        setGameId(state: any, value: string) {
          state.gameId = value;
        },
        setGameTitle(state: any, value: string) {
          state.gameTitle = value;
        },
        setSettingsGameId(state: any, value: string) {
          state.settingsGameId = value;
        },
        setInquiryId(state: any, value: string) {
          state.inquiryId = value;
        },
        setMailId(state: any, value: number) {
          state.mailId = value;
        },
        setSelectedMailId(state: any, value: number) {
          state.selectedMailId = value;
        },
        setTargetAccountId(state: any, value: string) {
          state.targetAccountId = value;
        },
        setIsAlert(state: any, value: boolean) {
          state.isAlert = value;
        },
        setIsAccountAuthError(state: any, value: boolean) {
          state.isAccountAuthError = value;
        },
        setIsNewTab(state: any, value: boolean) {
          state.isNewTab = value;
        },
        setPresetId(state: any, value: number | undefined) {
          state.presetId = value;
        },
        setCount(state: any, value: number) {
          state.notificationCount = value;
        },
        setSearchCondition(state: any, value: SearchCondition) {
          state.searchCondition = value;
        },
        setMailDetailsInfo(state: any, value: MailDetailsStoreInfo) {
          state.mailDetailsInfo = value;
        },
        setMailSupportInfo(state: any, value: MailSupportStoreInfo) {
          state.mailSupportInfo = value;
        },
        setBulkMailSupportInfo(state: any, value: BulkMailSupportStoreInfo) {
          state.bulkMailSupportInfo = value;
        },
        setMailDetailsDisplayType(state: any, value: string) {
          state.mailDetailsInfo.displayType = value;
        },
        setMailDetailsCaseId(state: any, value: string) {
          state.mailDetailsInfo.caseId = value;
        },
        setMailDetailsUserId(state: any, value: string) {
          state.mailDetailsInfo.userId = value;
        },
      },
      actions: {
        setPageName({ commit }, value: string) {
          commit('setPageName', value);
        },
        setTrialGameIdList({ commit }, value: string[]) {
          commit('setTrialGameIdList', value);
        },
        setAccountInfo({ commit }, value: AccountInfo) {
          commit('setAccountInfo', value);
        },
        setIsAuthenticated({ commit }, value: boolean) {
          commit('setIsAuthenticated', value);
        },
        setAccountId({ commit }, value: string) {
          commit('setAccountId', value);
        },
        setAccountEmail({ commit }, value: string) {
          commit('setAccountEmail', value);
        },
        setAccountRole({ commit }, value: number) {
          commit('setAccountRole', value);
        },
        setIsLinkedCygames({ commit }, value: boolean) {
          commit('setIsLinkedCygames', value);
        },
        setRoleName({ commit }, value: string) {
          commit('setRoleName', value);
        },
        setAccountName({ commit }, value: string) {
          commit('setAccountName', value);
        },
        setToken({ commit }, value: string) {
          commit('setToken', value);
        },
        setCompanyName({ commit }, value: string) {
          commit('setCompanyName', value);
        },
        setGameId({ commit }, value: string) {
          commit('setGameId', value);
        },
        setGameTitle({ commit }, value: string) {
          commit('setGameTitle', value);
        },
        setSettingsGameId({ commit }, value: string) {
          commit('setSettingsGameId', value);
        },
        setInquiryId({ commit }, value: string) {
          commit('setInquiryId', value);
        },
        setMailId({ commit }, value: number) {
          commit('setMailId', value);
        },
        setSelectedMailId({ commit }, value: number) {
          commit('setSelectedMailId', value);
        },
        setTargetAccountId({ commit }, value: string) {
          commit('setTargetAccountId', value);
        },
        setCount({ commit }, value: number) {
          commit('setCount', value);
        },
        setIsAlert({ commit }, value: boolean) {
          commit('setIsAlert', value);
        },
        setIsAccountAuthError({ commit }, value: boolean) {
          commit('setIsAccountAuthError', value);
        },
        setIsNewTab({ commit }, value: boolean) {
          commit('setIsNewTab', value);
        },
        setPresetId({ commit }, value: number | undefined) {
          commit('setPresetId', value);
        },
        setSearchCondition({ commit }, value: SearchCondition) {
          commit('setSearchCondition', value);
        },
        setMailDetailsInfo({ commit }, value: MailDetailsStoreInfo) {
          commit('setMailDetailsInfo', value);
        },
        setMailSupportInfo({ commit }, value: MailSupportStoreInfo) {
          commit('setMailSupportInfo', value);
        },
        setBulkMailSupportInfo({ commit }, value: BulkMailSupportStoreInfo) {
          commit('setBulkMailSupportInfo', value);
        },
        setMailDetailsDisplayType({ commit }, value: string) {
          commit('setMailDetailsDisplayType', value);
        },
        setMailDetailsCaseId({ commit }, value: string) {
          commit('setMailDetailsCaseId', value);
        },
        setMailDetailsUserId({ commit }, value: string) {
          commit('setMailDetailsUserId', value);
        },
      },
      modules: {},
    });

    return;
  }
}
