<template>
  <v-card class="card">
    <h4 class="mt-5 mainMessage">{{ item.main.message }}</h4>
    <div v-if="item.sub.visivle" style="text-align: center;">
      <div class="subMessage">{{ item.sub.message }}</div>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="btn" color="blue" variant="outlined" @click="$emit('cancelCheck')">
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn class="btn" color="blue" variant="flat" @click="$emit('yesCheck')">
        {{ $t('common.ok') }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
  /**
  * ダイアログ表示用メッセージ
  */
  item: {
    type: Object,
    required: true
  }
})

props.item;
</script>


<style scoped>
.btn {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 140px;
  height: 35px;
}

.mainMessage {
  text-align: center;
  white-space: pre-line;
}

.subMessage {
  margin-top: 8px;
  white-space: pre-line;
  color: red;
  font-size: 14px;
}
</style>