<template>
  <v-app-bar color="#333333" height="40" style="min-width: 1900px;" absolute>
    <v-tabs>
      <v-tab id="mail-value" color="white" class="text-capitalize" @click="$router.push(PAGE_INFO.MAIL.MAIL_LIST.PATH)">
        {{ $t('common.mailList') }}
        <a>
          <v-badge id="budge" v-if="store.getNotificationCount() !== 0" :content="store.getNotificationCount()" bottom
            color="red" offset-x="-20" offset-y="-6"></v-badge>
        </a>
      </v-tab>

      <v-menu id="admin-menu" open-on-click v-if="store.getAccountRole() !== ROLE_TYPE.ADMIN">
        <template v-slot:activator="{ props }">
          <v-tab class="tab-color text-capitalize" v-bind="props" color="white"> {{ $t('common.admin') }} ▼ </v-tab>
        </template>

        <v-list>
          <v-list-item id="admin-value" v-for="(adminLink, i) in adminLinkList" :key="i" :value="adminLink"
            @click="$router.push(adminLink.url)">
            <v-list-item-title>{{ adminLink.menuText }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu id="setiings-menu" open-on-click @click="store.setTargetAccountId(store.getAccountId())">
        <template v-slot:activator="{ props }">
          <v-tab class="tab-color text-capitalize" v-bind="props" color="white"> {{ $t('common.settings') }} ▼
          </v-tab>
        </template>

        <v-list>
          <v-list-item id="settings-value" v-for="(settingLink, i) in settingLinkList" :key="i" :value="settingLink"
            @click="$router.push({ path: settingLink.url })">
            <v-list-item-title>{{ settingLink.menuText }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script setup lang="ts">
import i18n from '@/constants/language';
import { StoreWrapper } from '@/store/storeWrapper';
import { watchEffect } from 'vue';
import { ref } from 'vue';
import { PAGE_INFO, ROLE_TYPE } from '@/constants/const';
import { useAuth0 } from "@auth0/auth0-vue";

// Auth0のAPIモジュール
const { isLoading } = useAuth0();

const store = StoreWrapper.getInstance();

interface linkList {
  menuText: string;
  url: string;
}

let adminLinkList = ref<linkList[]>([]);
let settingLinkList = ref<linkList[]>([]);

/**
* アカウント種別を基準に、メニューバーの設定をする
* @returns {void}
*/
const setMenuContents = (): void => {
  settingLinkList.value = new Array();
  adminLinkList.value = new Array();

  if (store.getAccountRole() === ROLE_TYPE.ADMIN) {
    // 管理アカウント
    settingLinkList.value.push({ menuText: i18n.global.t('word.settings.accountSettings'), url: PAGE_INFO.SETTING.ACCOUNT.SETTING_ADMIN_ACCOUNT.PATH });
    settingLinkList.value.push({ menuText: i18n.global.t('word.settings.accountList'), url: PAGE_INFO.SETTING.ACCOUNT.ACCOUNT_LIST.PATH });
    settingLinkList.value.push({ menuText: i18n.global.t('word.settings.gameTitle.gameTitleList'), url: PAGE_INFO.SETTING.GAME_TITLE.GAME_TITLE_LIST.PATH });
  } else if (store.getAccountRole() === ROLE_TYPE.PARTNER_ADMIN) {
    // パートナー管理アカウント
    settingLinkList.value.push({ menuText: i18n.global.t('word.settings.accountSettings'), url: PAGE_INFO.SETTING.ACCOUNT.SETTING_PARTNER_ACCOUNT.PATH });
    settingLinkList.value.push({ menuText: i18n.global.t('word.settings.gameTitle.gameTitleList'), url: PAGE_INFO.SETTING.GAME_TITLE.GAME_TITLE_LIST.PATH });
    adminLinkList.value.push({ menuText: i18n.global.t('word.manageTemplate.title'), url: PAGE_INFO.ADMIN.MANAGE_TEMPLATE.PATH });
    adminLinkList.value.push({ menuText: i18n.global.t('word.manageCategory.title'), url: PAGE_INFO.ADMIN.MANAGE_CATEGORY.PATH });
  } else if (store.getAccountRole() === ROLE_TYPE.PARTNER) {
    // パートナーアカウント
    settingLinkList.value.push({ menuText: i18n.global.t('word.settings.accountSettings'), url: PAGE_INFO.SETTING.ACCOUNT.SETTING_PARTNER_ACCOUNT.PATH });
    adminLinkList.value.push({ menuText: i18n.global.t('word.manageTemplate.title'), url: PAGE_INFO.ADMIN.MANAGE_TEMPLATE.PATH });
    adminLinkList.value.push({ menuText: i18n.global.t('word.manageCategory.title'), url: PAGE_INFO.ADMIN.MANAGE_CATEGORY.PATH });
  }

  return;
}

/**
* メニューバーの設定をする
* @returns {void}
*/
watchEffect(() => {
  // Auth0のロード完了まで待つ
  if (isLoading.value) return;

  // メニュー内容をセット
  if (store.isAuthenticated() && store.getAccountRole() !== ROLE_TYPE.NONE) setMenuContents();
  return;
});

</script>

<style scoped>
.item {
  color: #b3b3b3;
  text-decoration: none;
}

.v-tab {
  width: 250px;
}

.tab-color {
  color: #b3b3b3;
}
</style>