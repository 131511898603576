<template>
  <v-card>
    <v-card-title>
      <div class="mt-3 ml-3">
        <span class="text-h5">{{ $t('word.mailSupport.mailSendingResult') }}</span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-container class="justify-start pb-0">
        <div v-if="!sendMailInfo.isError">{{ $t('NS00302') }}</div>
        <div v-if="sendMailInfo.isError">{{ $t(sendMailInfo.message) }}</div>
        <div class="mt-5" style="max-width: 400px; min-width: 400px;">
          <v-list density="compact" max-height="200">
            <v-list-subheader>{{ $t('word.common.mailInfo.caseId') }}</v-list-subheader>
            <v-list-item v-for="(item, i) in caseIdList" :key="i" :value="item">
              <v-list-item-title>・{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="btn-l" color="blue-darken-1" variant="flat" @click="$emit('sendMailComplete')">
        {{ $t('common.ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
  /**
* 案件IDリスト
*/
  caseIdList: {
    type: Array,
    required: true
  },
  /**
* 送信後のメール情報
*/
  sendMailInfo: {
    type: Object,
    required: true
  },
})

props.caseIdList, props.sendMailInfo;
</script>