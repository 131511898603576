import { createI18n } from 'vue-i18n';

const messages = {
  /**
   * 日本語の定義
   */
  ja: {
    // システム共通：メッセージ
    NS00001: 'ログアウトします。宜しいでしょうか？',
    NS00002: '添付ファイルを取得中です。',
    NS00003: '添付ファイルの取得が完了しました。',
    NS00004: '添付ファイルを取得中です。しばらくお待ちください。',
    NS00005: 'エクスポートが完了しました。',
    NS00006: 'データをエクスポートしています。しばらくお待ちください。',
    NS00007: '該当なし',
    NS00008: '抽出されたメールデータをエクスポートしますか？',
    NS00009: 'エクスポート可能なメールは最大5万件です。\n最大数を超えないよう詳細検索でメールを抽出してください。\n\n抽出件数：',
    NS00010: 'エクスポートするメール情報を抽出しています。',
    ES00001: 'システムエラーが発生しました。',
    ES00002: '添付ファイルの取得に失敗しました。',
    ES00003: '一部添付ファイルの取得に失敗しました。',
    ES00004: '添付ファイルの取得に失敗した為、ダウンロードできません。',
    ES00005: 'タイムアウトが発生しました。',
    ES00006: 'ページを読み込めませんでした。',
    ES00007: 'エクスポートに失敗しました。',
    ES00008: '最大件数を超えているためエクスポートできません。',
    ES00009: '選択してください',
    ES00010: '編集中の内容が失われる可能性があります。ページを離れますか？',
    // メール一覧ページ
    NS00101: 'メール情報がありません。',
    NS00102: '読み込み中です。',
    NS00103: 'メール情報の取得が完了しました。',
    NS00104: '一括変更が完了しました。',
    NS00105: '以下の案件IDに対して、',
    NS00106: 'を実行します。',
    NS00107: 'Preset {selectingPreset} に設定されている検索条件を読み込みますか？',
    NS00108: '現在設定されている条件をPreset {selectingPreset} に登録しますか？',
    NS00109: 'プリセットを登録しました。',
    ES00101: '一括変更に失敗しました。',
    ES00102: '詳細検索に失敗しました。',
    ES00103: 'フィルタの設定に失敗しました。',
    ES00104: 'メール情報の取得ができませんでした。',
    ES00105: 'メール情報の一括変更に失敗しました。',
    ES00106: 'ステータスが完了もしくは対応不要となっているメールが含まれています。',
    ES00107: '他の担当者が担当する案件メールが含まれています。',
    ES00108: 'プリセットの登録に失敗しました。',
    ES00109: 'メールが選択されていません。',
    // メール詳細ページ
    NS00201: '{answerLimitNumber}時間以内に必ずご対応ください',
    NS00202: '対応期限が迫っております\n{answerLimitNumber}時間以内に必ずご対応ください',
    NS00203: 'あと{answerLimitNumber}時間で対応期限となります\n早急にご対応ください',
    NS00204: '回答期限が過ぎています\n一刻も早くご対応をお願いします',
    NS00205: 'メール情報の変更が完了しました。',
    NS00206: '「{caseId}」に対する一括変更',
    ES00201: 'メール情報の変更に失敗しました。',
    // メール対応ページ
    NS00301: '一時保存します。宜しいでしょうか？',
    NS00302: '以下の案件IDに対し、メールを送信しました。',
    NS00303: 'フリーコメントを保存します。宜しいでしょうか？',
    NS00304: 'フリーコメントを保存しました。',
    NS00305: '一時保存が完了しました。',
    NS00306: 'この案件の担当者を自身に割り当てますか？',
    NS00307: 'この案件の担当者を自身に割り当てました。',
    NS00308: '案件ID {caseId} に対するメール作成',
    ES00301: '一時保存に失敗しました。',
    ES00302: 'メール送信に失敗しました。',
    ES00303: 'フリーコメントの保存に失敗しました。',
    ES00304: '添付ファイルサイズに誤りがあります。',
    ES00305: '添付ファイル数に誤りがあります。',
    ES00306: '送信禁止文字を含んでいたため、メール送信に失敗しました。',
    ES00307: '下記案件IDに対するメール送信に失敗しました。',
    ES00308: 'システムエラーが発生したため、メール送信に失敗しました。',
    ES00309: 'タイムアウトが発生したため、メール送信に失敗しました。',
    ES00310: '担当者の割り当てに失敗しました。',
    ES00311: '案件の担当者に他のユーザーが設定されているため、\nこの案件の対応をすることができません。',
    // 分類管理ページ
    NS00501: '分類の編集内容を破棄して宜しいでしょうか？',
    NS00502: '分類情報を保存します。宜しいでしょうか？',
    NS00503: '分類を保存しました。',
    NS00504: '分類を保存しました。\n\n※下記の分類は現在案件に設定されている為、削除できませんでした。',
    ES00501: '分類情報を取得できませんでした。',
    ES00502: '分類を保存できませんでした。',
    ES00503: '分類を保存できませんでした。\n\n※下記の分類は現在案件に設定されている為、削除できませんでした。',
    // テンプレート管理ページ
    NS00601: 'テンプレートの編集内容を破棄して宜しいでしょうか？',
    NS00602: 'テンプレート情報を保存します。宜しいでしょうか？',
    NS00603: 'テンプレートを保存しました。',
    ES00601: 'テンプレート情報を取得できませんでした。',
    ES00602: 'テンプレートを保存できませんでした。',
    ES00603: 'ファイルが見つかりません。',
    ES00604: 'ファイルのフォーマットが正しくありません。',
    // 管理アカウント作成ページ
    NS00701: 'アカウントを登録します。宜しいでしょうか？',
    NS00702: 'アカウントを作成しました。',
    ES00701: 'アカウントを作成できませんでした。',
    // アカウント一覧ページ
    NS00801: '以下のアカウントに対して',
    NS00802: '【削除】',
    NS00803: 'を実行します。',
    NS00804: 'アカウント情報がありません。',
    //アカウント設定ページ
    NS00901: 'パスワードを変更しますか？',
    NS00902: 'アカウント設定を保存しますか？',
    NS00903: 'アカウントを削除しますか？',
    NS00904: 'アカウント設定を変更しました。',
    NS00905: 'パスワード変更URLをメールで送信しました。\nメールをご確認ください。',
    NS00906: 'アカウントを削除しました。',
    NS00907: '※メールアドレスを変更すると再ログインが必要になります。',
    NS00908: '※メールアドレスが変更されている為、一度ログアウトします。\n認証メールを確認後、再度ログインしてください。',
    NS00909: 'メールアドレスが変更された為、ログアウトします。\n認証メールを確認後、再度ログインしてください。',
    ES00901: 'アカウント設定を取得できませんでした。',
    ES00902: 'アカウント設定を保存できませんでした。',
    ES00903: 'アカウントを削除できませんでした。',
    ES00904: 'パスワード変更URLをメールで送信できませんでした。',
    // ゲームタイトル一覧ページ
    NS10101: 'ゲームタイトル情報がありません。',
    // ゲームタイトル設定ページ
    NS10201: '自動返信メール設定を保存しました。',
    NS10202: '送信禁止文字設定を保存しました。',
    NS10203: '除外メール設定を保存しました。',
    NS10204: '自動返信メール設定を保存しますか？',
    NS10205: '送信禁止文字設定を保存しますか？',
    NS10206: '送信禁止文字設定の編集内容を破棄して宜しいでしょうか？',
    NS10207: '除外メール設定を保存しますか？',
    NS10208: '除外メール設定の編集内容を破棄して宜しいでしょうか？',
    ES10201: 'ゲームタイトル設定を取得できませんでした。',
    ES10202: '自動返信メール設定を保存できませんでした。',
    ES10203: '送信禁止文字設定を保存できませんでした。',
    ES10204: '除外メール設定を保存できませんでした。',

    // システム共通ワード
    common: {
      logout: 'ログアウト',
      mailList: 'メール一覧',
      edit: '編集',
      delete: '削除',
      cancel: 'キャンセル',
      save: '保存',
      add: '追加',
      ok: 'はい',
      settings: '設定',
      admin: '管理',
      back: '戻る',
      submit: '送信',
      select: '選択',
      search: '検索',
      detailedSearch: '詳細検索',
      passWordChange: 'パスワード変更',
      list: '一覧',
      details: '詳細',
      accountList: 'アカウント一覧',
      support: '対応',
      monday: '月',
      tuesday: '火',
      wednesday: '水',
      thursday: '木',
      friday: '金',
      saturday: '土',
      sunday: '日',
      clear: 'クリア',
    },
    // ワード
    word: {
      // メール系：ワード
      common: {
        mailInfo: {
          inquiryNo: '問い合わせID',
          caseId: '案件ID',
          userId: 'ユーザID',
          subject: '件名',
          dateReceived: '受付日時',
          answerLimit: '回答期限',
          status: 'ステータス',
          category: '分類',
          manager: '担当者',
          freeCommentAdd: 'フリーコメント(追記)',
          freeComment: 'フリーコメント',
          sendDateStart: '受付日時（検索範囲：開始）',
          sendDateEnd: '受付日時（検索範囲：終了）',
          mailbody: '本文',
          mailContent: 'メール内容',
          logDetail: 'ログ',
          action: 'アクション',
          sendingReceivingDate: '受送信日時',
          preview: 'プレビュー',
          presetCondition: 'プリセット条件',
        },
      },
      // メール一覧ページ
      mailList: {
        bulkChange: '一括変更',
        bulkResponse: '一括対応',
        filter: 'フィルタ',
        run: '実行',
        reload: '再読み込み',
        statusChange: 'ステータス変更',
        categoryChange: '分類変更',
        managerChange: '担当者変更',
        confirm: '確認',
        bulkChange1: '以下の案件IDに対して、',
        bulkChange2: 'を実行します。',
        itemCount: '表示件数',
        sortConditions: 'ソート条件',
        ascending: '昇順',
        descending: '降順',
        limit: '期限切れ',
        answer: '対応済み',
        inHour: '{hour}時間以内',
      },
      // メール詳細ページ
      mailDetails: {
        displaySwitching: '表示切替',
        caseId: '案件IDに紐づく対応一覧',
        userId: 'ユーザIDに紐づく対応一覧',
        mobageId: 'mobage Id',
        userAgent: 'User Agent',
      },
      // メール対応ページ
      mailSupport: {
        pastMail: '過去メール',
        mailContent: 'メール内容',
        template: 'テンプレート',
        attachment: '添付',
        save: '一時保存',
        mailSend: 'メール送信',
        mailSubmitComfirm: 'メール送信確認',
        mailSendingResult: 'メール送信結果',
        supportStart: '対応開始',
      },
      // 分類管理ページ
      manageCategory: {
        title: '分類管理',
      },
      // テンプレート管理ページ
      manageTemplate: {
        title: 'テンプレート管理',
        import: 'インポート',
        export: 'エクスポート',
        templateName: 'テンプレート名',
        templateContent: '内容',
        templateSelect: 'テンプレート選択',
      },
      // 設定系：ワード
      settings: {
        accountList: 'アカウント一覧',
        createAccount: '管理アカウント作成',
        accountSettings: 'アカウント設定',
        create: '新規登録',
        accountId: 'アカウントID',
        displayName: '表示名',
        mailAddress: 'メールアドレス',
        companyName: '会社名',
        accountType: 'アカウント種別',
        status: 'ステータス',
        registration: '登録',
        accountActiveSettings: 'アカウント 稼働/停止設定',
        run: '稼働',
        stop: '停止',
        slackSettings: 'Slack通知設定',
        active: '有効',
        inactive: '無効',
        accountDelete: 'アカウント削除',
        newTab: 'メール詳細を新規タブで表示する',
        emailNotificationSettings: 'メール通知設定',
        // 設定/ゲームタイトル系ワード
        gameTitle: {
          gameTitleList: 'ゲームタイトル一覧',
          gameTitleSettings: 'ゲームタイトル設定',
          gameTitileId: 'ゲームタイトルID',
          gameTitleName: 'ゲームタイトル名',
          companyName: '会社名',
          mailAddress: 'タイトル別メールアドレス',
          autoReplySetting: '自動返信メール設定',
          forbiddenWordSetting: '送信禁止文字設定',
          excludesEmailSetting: '除外メール設定',
          accountList: 'アカウント一覧',
          forbiddenWord: '禁止ワード',
          target: '対象',
          excludeWord: '除外ワード',
          mailTitle: 'メールタイトル',
          mailBody: '本文',
        },
      },
    },
    // フィルタ・詳細検索のソート条件リスト
    list: {
      sort: [
        { label: '問い合わせID', value: 'inquiryId' },
        { label: '案件ID', value: 'caseId' },
        { label: 'ユーザーID', value: 'userId' },
        { label: 'タイトル', value: 'title' },
        { label: '受付日時', value: 'receptionDate' },
        { label: '回答期限', value: 'answerLimit' },
        { label: 'ステータス', value: 'status' },
        { label: '分類', value: 'category' },
        { label: '担当者', value: 'manager' },
      ],
    },
  },

  /**
   * 英語の定義
   */
  en: {
    // システム共通：メッセージ
    NS00001: 'Would you like to log out?',
    NS00002: 'Currently retrieving attached file.',
    NS00003: 'Attached file retrieval completed.',
    NS00004: 'Retrieving attached file. Please wait for a while.',
    NS00005: 'Export is complete!.',
    NS00006: 'Exporting data. Please wait a moment.',
    NS00007: 'No data available',
    NS00008: 'Do you want to export the extracted email data?',
    NS00009:
      'The maximum number of emails that can be exported is 50,000.\n Please extract emails using advanced search so that the maximum number is not exceeded.\n\nNumber of emails to be extracted:',
    NS00010: 'Extracts email information to be exported.',
    ES00001: 'A system error has occurred.',
    ES00002: 'Failed to retrieve the attached file.',
    ES00003: 'Failed to retrieve some attached file.',
    ES00004: 'Unable to download the file due to a failure in retrieving the attached file.',
    ES00005: 'Timeout.',
    ES00006: 'Page could not be loaded.',
    ES00007: 'Export failed.',
    ES00008: 'Unable to export because the maximum number of items has been exceeded.',
    ES00009: 'Please select',
    ES00010: 'You may lose the content you are editing. Do you want to leave the page?',
    // メール一覧ページ
    NS00101: 'No email information available.',
    NS00102: 'Loading...',
    NS00103: 'Email information retrieval completed.',
    NS00104: 'Bulk change has been executed.',
    NS00105: 'For the following case ID(s),',
    NS00106: 'Would you execute?',
    NS00107: 'Do you want to load the search criteria set in Preset {selectingPreset}?',
    NS00108: 'Do you want to register the currently set condition in Preset {selectingPreset}?',
    NS00109: 'Preset registered.',
    ES00101: 'Bulk change failed.',
    ES00102: 'Detailed search failed.',
    ES00103: 'Filtering failed.',
    ES00104: 'Failed to retrieve email information.',
    ES00105: 'Failed to bulk change email information.',
    ES00106: 'Includes emails with status completed or not required.',
    ES00107: 'It includes emails assigned to others',
    ES00108: 'Failed to register preset.',
    ES00109: 'Email is not selected.',
    // メール詳細ページ
    NS00201: 'Please be sure to respond within {answerLimitNumber} hours.',
    NS00202: 'Response deadline is approaching.\nPlease be sure to respond within {answerLimitNumber} hours.',
    NS00203: 'Response deadline is in {answerLimitNumber} hours.\nPlease respond as soon as possible.',
    NS00204: 'The deadline for response has passed.\nPlease respond as soon as possible.',
    NS00205: 'Your email information has been changed.',
    NS00206: 'Bulk change for「{caseId}」',
    ES00201: 'Failed to change email information.',
    // メール対応ページ
    NS00301: 'Would you like to save as a draft?',
    NS00302: 'Sent emails for the following case ID(s).',
    NS00303: 'Save free comment. Would you like?',
    NS00304: 'Free comment saved.',
    NS00305: 'Draft saved successfully.',
    NS00306: 'Do you wish to assign yourself as the person in charge of this case?',
    NS00307: 'Assigned the person in charge to himself.',
    NS00308: 'Send e-mail to case ID {caseId} ',
    ES00301: 'Failed to save draft.',
    ES00302: 'Failed to send email.',
    ES00303: 'Failed to save free comment.',
    ES00304: 'Incorrect attachment file size.',
    ES00305: 'Incorrect number of attachment files.',
    ES00306: 'Failed to send email due to prohibited word.',
    ES00307: 'Failed to send email for the following case ID.',
    ES00308: 'Email transmission failed due to a system error.',
    ES00309: 'Failed to send e-mail because a timeout occurred.',
    ES00310: 'Failed to assign the person in charge.',
    ES00311: 'Because another user is set as the person in charge of the case, he/she is unable to respond to this case.',
    // 分類管理ページ
    NS00501: 'Would you like to discard the editing contents of the category?',
    NS00502: 'Would you like to save the category information?',
    NS00503: 'The category has been saved.',
    NS00504: 'Category saved.\n\n※The following category could not be deleted because it is currently set for the case. ',
    ES00501: 'Could not retrieve the category information.',
    ES00502: 'Could not save the category.',
    ES00503: 'Category could not be saved. \n\nThe following category could not be deleted because it is currently set in the case.',
    // テンプレート管理ページ
    NS00601: 'Would you like to discard the editing contents of the template?',
    NS00602: 'Would you like to save the template information?',
    NS00603: 'The template has been saved.',
    ES00601: 'Could not retrieve the template information.',
    ES00602: 'Could not save the template.',
    ES00603: 'File not found.',
    ES00604: 'Incorrect file format.',
    // 管理アカウント作成ページ
    NS00701: 'Would you like to register an account?',
    NS00702: 'Account created successfully.',
    ES00701: 'Failed to create account.',
    // アカウント一覧ページ
    NS00801: 'For the following account(s),',
    NS00802: '[Delete]',
    NS00803: 'Would you execute?',
    NS00804: 'No account information available.',
    // アカウント設定ページ
    NS00901: 'Would you like to change the password?',
    NS00902: 'Would you like to save account settings?',
    NS00903: 'Would you like to delete the account?',
    NS00904: 'Account settings changed.',
    NS00905: 'Password change URL sent via email. Please check your email.',
    NS00906: 'Account deleted.',
    NS00907: '*If you change your e-mail address, you will need to log in again.',
    NS00908:'*Restart the system once because the email address has been changed. After confirming the authentication email, please log in again.',
    NS00909: 'I am logging out because my e-mail address has changed.\nAfter confirming the authentication email, please log in again.',
    ES00901: 'Failed to retrieve account settings.',
    ES00902: 'Failed to save account settings.',
    ES00903: 'Failed to delete account.',
    ES00904: 'Failed to send the password change URL via email.',
    // ゲームタイトル一覧ページ
    NS10101: 'No game title information available.',
    // ゲームタイトル設定ページ
    NS10201: 'Saved auto-reply email settings.',
    NS10202: 'Saved prohibited word settings.',
    NS10203: 'Saved excluded email settings.',
    NS10204: 'Would you save auto-reply email settings?',
    NS10205: 'Would you save prohibited word settings?',
    NS10206: 'Would you discard edits to prohibited word settings?',
    NS10207: 'Would you save excluded email settings?',
    NS10208: 'Would you discard edits to excluded email settings?',
    ES10201: 'Failed to retrieve game title settings.',
    ES10202: 'Failed to save auto-reply email settings.',
    ES10203: 'Failed to save prohibited word settings.',
    ES10204: 'Failed to save excluded email settings.',

    // システム共通：ワード
    common: {
      logout: 'Logout',
      mailList: 'Mail List',
      edit: 'Edit',
      delete: 'Delete',
      cancel: 'Cancel',
      save: 'Save',
      add: 'Add',
      ok: 'OK',
      back: 'Return',
      settings: 'Settings',
      admin: 'Admin',
      submit: 'Submit',
      select: 'Select',
      passWordChange: 'Change PassWord',
      search: 'search',
      detailedSearch: 'Detailed Search',
      list: 'List',
      details: 'Detail',
      accountList: 'Account List',
      support: 'Support',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
      clear: 'clear',
    },
    word: {
      // メール系：ワード
      common: {
        mailInfo: {
          inquiryNo: 'Inquiry ID',
          caseId: 'Case Id',
          userId: 'UserId',
          subject: 'Subject',
          dateReceived: 'Date Received',
          answerLimit: 'Answer Limit',
          status: 'Status',
          category: 'Category',
          manager: 'Manager',
          freeCommentAdd: 'FreeComment(postscript)',
          freeComment: 'Free Comment',
          sendDateStart: 'ReceptionDate (range:start)',
          sendDateEnd: 'ReceptionDate (range:end)',
          mailbody: 'Text',
          mailContent: 'Mail Content',
          logDetail: 'Log',
          action: 'Action',
          sendingReceivingDate: 'Date Sent/Received',
          preview: 'preview',
          presetCondition: 'Preset Condition',
        },
      },
      // メール一覧ページ
      mailList: {
        bulkChange: 'Bulk Change',
        bulkResponse: 'Bulk Response',
        filter: 'Filter',
        run: 'Run',
        reload: 'Reload',
        statusChange: 'Status Change',
        categoryChange: 'Category Change',
        managerChange: 'Manager Change',
        confirm: 'Confirm',
        bulkChange1: 'For the following inquiry,',
        bulkChange2: 'Would you execute?',
        itemCount: 'Item Count',
        sortConditions: 'Sort Conditions',
        ascending: 'Ascending',
        descending: 'Descending',
        limit: 'Limit over',
        answer: '	End supported',
        inHour: 'in {hour} hour',
      },
      // メール詳細ページ
      mailDetails: {
        displaySwitching: 'Switch Display',
        caseId: 'List of correspondence tied to the case ID',
        userId: 'List of correspondence tied to the user ID',
        mobageId: 'mobage Id',
        userAgent: 'User Agent',
      },
      // メール対応ページ
      mailSupport: {
        pastMail: 'Past Mail',
        mailContent: 'Mail Content',
        template: 'Template',
        attachment: 'Attachment',
        save: 'Save',
        mailSend: 'Mail Send',
        mailSubmitComfirm: 'Confirmation of email send',
        mailSendingResult: 'Mail Sending Result',
        supportStart: 'supportStart',
      },
      // 分類管理ページ
      manageCategory: {
        title: 'Category Manegement',
      },
      // テンプレート管理ページ
      manageTemplate: {
        title: 'Template Manegement',
        import: 'Import',
        export: 'Export',
        templateName: 'Template Name',
        templateContent: 'Template Content',
        templateSelect: 'Template Select',
      },
      // 設定系ワード
      settings: {
        accountList: 'Account List',
        createAccount: 'Create Admin Account',
        accountSettings: 'Account Settings',
        create: 'Create',
        accountId: 'Account ID',
        displayName: 'Display Name',
        mailAddress: 'Email Address',
        companyName: 'Company Name',
        accountType: 'Account Type',
        status: 'Status',
        registration: 'Registration',
        accountActiveSettings: 'Account Active/Stop Settings',
        run: 'Active',
        stop: 'Stop',
        slackSettings: 'Slack Notification Settings',
        active: 'Active',
        inactive: 'Inactive',
        accountDelete: 'Delete Account',
        newTab: 'Display email details in a new tab',
        emailNotificationSettings: 'Email Notification Settings',
        // 設定/ゲームタイトル：ワード
        gameTitle: {
          gameTitleList: 'Game Title List',
          gameTitleSettings: 'Game Title Settings',
          gameTitileId: 'Game Titile ID',
          gameTitleName: 'Game Titile Name',
          companyName: 'Company Name',
          mailAddress: 'Email address for inquires by title',
          autoReplySetting: 'Autoreply Settings',
          forbiddenWordSetting: 'Prohibited Word Settings',
          excludesEmailSetting: 'Excluded Email Settings',
          accountList: 'Account List',
          forbiddenWord: 'Prohibited Words',
          target: 'Target',
          excludeWord: 'Excluded Words',
          mailTitle: 'Email Title',
          mailBody: 'Text',
        },
      },
    },
  },

  /**
   * 中国語の定義
   */
  ch: {
    // システム共通：メッセージ
    NS00001: '是否确定要登出？',
    NS00002: '取得附件中。',
    NS00003: '已取得附件。',
    NS00004: '取得附件中，请稍后。',
    NS00005: '导出已完成。',
    NS00006: '正在导出信息，请稍后。',
    NS00007: '无符合条件结果',
    NS00008: '确定导出筛选中的邮件信息吗？',
    NS00009: '最多可导出5万封邮件\n使用高级筛选时请注意不要 \n\n超过上限 :',
    NS00010: '正在筛选要导出的邮件信息。',
    ES00001: '系统发生异常。',
    ES00002: '附件取得失败。',
    ES00003: '一部分的附件取得失败。',
    ES00004: '因附件取得失败，无法下载。',
    ES00005: '系统反应超时。',
    ES00006: '无法加载页面。',
    ES00007: '导出失败。',
    ES00008: '因超过件数上限，无法导出。',
    ES00009: '请选择',
    ES00010: '正在编辑的内容可能会丢失。 您想离开页面吗？',
    // メール一覧ページ
    NS00101: '查无邮件信息。',
    NS00102: '加载中。',
    NS00103: '邮件信息已取得。',
    NS00104: '批量更改已完成。',
    NS00105: '针对以下案件ID。',
    NS00106: '执行',
    NS00107: '是否要加载配置 {selectingPreset} 的筛选条件？',
    NS00108: '是否要将目前的筛选条件保存至配置 {selectingPreset} ？',
    NS00109: '已保存配置。',
    ES00101: '批量更改失败。',
    ES00102: '高级搜索失败。',
    ES00103: '筛选器设置失败。',
    ES00104: '无法取得邮件信息。',
    ES00105: '邮件信息的批量更改失败。',
    ES00106: '包含了状态为已完成或是不需回复的邮件。',
    ES00107: '包含了其他人负责的邮件。',
    ES00108: '筛选配置保存失败。',
    ES00109: '未选取任何邮件。',
    // メール詳細ページ
    NS00201: '需要在{answerLimitNumber}小时内回复。',
    NS00202: '已经接近回复期限，需要在{answerLimitNumber}小时内回复。',
    NS00203: '距离回复期限剩{answerLimitNumber}小时，请优先回复。',
    NS00204: '已超过回复期限，请优先回复。',
    NS00205: '邮件信息已变更完成。',
    NS00206: '对「{caseId}」批量更改',
    ES00201: '邮件信息变更失败。',
    // メール対応ページ
    NS00301: '确定暂时保存吗？',
    NS00302: '已将以下案件ID的邮件送出。',
    NS00303: '确定保存自由批注吗？',
    NS00304: '已保存自由批注。',
    NS00305: '已暂时保存。',
    NS00306: '是否要将本案件的负责人指定为自己？',
    NS00307: '已将本案件的负责人指定为自己。',
    NS00308: '对 {caseId} 发送邮件',
    ES00301: '暂时保存失败。',
    ES00302: '邮件发送失败。',
    ES00303: '自由批注保存失败。',
    ES00304: '附件容量不正确。',
    ES00305: '附件数量不正确。',
    ES00306: '因包含禁用语，邮件发送失败。',
    ES00307: '以下案件ID的邮件发送失败。',
    ES00308: '因发生系统异常，邮件发送失败。',
    ES00309: '因系统超时，邮件发送失败。',
    ES00310: '负责人指定失败。',
    ES00311: '因本案件的负责人已指定为其他人，不能回复此案件。',
    // 分類管理ページ
    NS00501: '是否要放弃现在的分类编辑内容？',
    NS00502: '是否要保存分类信息？',
    NS00503: '已保存分类信息。',
    NS00504: '已保存分类信息。\n\n因以下的分类正用于案件、无法删除。',
    ES00501: '无法取得模版信息。',
    ES00502: '无法保存模版信息。',
    ES00503: '无法保存模版信息。\n\n因以下的分类正用于案件、无法删除。',
    // テンプレート管理ページ
    NS00601: '是否要放弃现在的模版编辑内容？',
    NS00602: '是否要保存模版信息？',
    NS00603: '已保存模版信息。',
    ES00601: '无法取得模版信息。',
    ES00602: '无法保存模版信息。',
    ES00603: '找不到文件。',
    ES00604: '文件格式不正确。',
    // 管理アカウント作成ページ
    NS00701: '是否要注册账户？',
    NS00702: '已注册账户。',
    ES00701: '无法注册账户。',
    // アカウント一覧ページ
    NS00801: '对以下账户',
    NS00802: '【删除】',
    NS00803: '执行。',
    NS00804: '无账户信息。',
    // アカウント設定ページ
    NS00901: '是否变更密码？',
    NS00902: '是否保存账户设置？',
    NS00903: '是否要删除账户？',
    NS00904: '已保存账户设置。',
    NS00905: '已通过邮件发送变更密码专用链接给您、请检查邮件。',
    NS00906: '已删除账户。',
    NS00907: '*变更邮箱地址后需要重新登录。',
    NS00908: '*因邮箱地址变更了，将登出系统。\n请确认认证邮件后再次登录。',
    NS00909: '因邮箱地址变更了，将登出系统。\n请确认认证邮件后再次登录。',
    ES00901: '无法取得账户设置。',
    ES00902: '无法保存账户设置。',
    ES00903: '无法删除账户。',
    ES00904: '无法通过邮件发送变更密码专用链接。',
    // ゲームタイトル一覧ページ
    NS10101: '没有游戏信息。',
    // ゲームタイトル設定ページ
    NS10201: '已保存邮件自动回复设置。',
    NS10202: '已保存禁用语设置。',
    NS10203: '已保存邮件过滤设置。',
    NS10204: '是否保存邮件自动回复设置？',
    NS10205: '是否保存禁用语设置？',
    NS10206: '是否要放弃编辑中的禁用语设置内容？',
    NS10207: '是否保存邮件过滤设置？',
    NS10208: '是否要放弃编辑中的邮件过滤设置内容？',
    ES10201: '无法取得游戏设置。',
    ES10202: '无法保存邮件自动回复设置。',
    ES10203: '无法保存禁用语设置。',
    ES10204: '无法保存邮件过滤设置。',

    // システム共通：ワード
    common: {
      logout: '登出',
      mailList: '邮件列表',
      edit: '编辑',
      delete: '删除',
      cancel: '取消',
      save: '保存',
      add: '新增',
      ok: '是',
      settings: '设置',
      admin: '管理',
      back: '返回',
      submit: '发送邮件',
      select: '选取',
      search: '搜索',
      detailedSearch: '高级搜索',
      passWordChange: '更改密码',
      list: '列表',
      details: '详细信息',
      accountList: '账户列表',
      support: '回复',
      monday: '周一',
      tuesday: '周二',
      wednesday: '周三',
      thursday: '周四',
      friday: '周五',
      saturday: '周六',
      sunday: '周日',
      clear: '清除',
    },
    word: {
      // メール系：ワード
      common: {
        mailInfo: {
          inquiryNo: '信件ID',
          caseId: '案件ID',
          userId: '用户ID',
          subject: '标题',
          dateReceived: '来信时间',
          answerLimit: '回复期限',
          status: '状态',
          category: '分类',
          manager: '负责人',
          freeCommentAdd: '自由批注（补充）',
          freeComment: '自由批注',
          sendDateStart: '来信时间（搜索范围：开始）',
          sendDateEnd: '来信时间（搜索范围：结束）',
          mailbody: '正文',
          mailContent: '邮件内容',
          logDetail: '历史记录',
          action: '行为',
          sendingReceivingDate: '收发信时间',
          preview: '预览',
          presetCondition: '预设条件',
        },
      },
      // メール一覧ページ
      mailList: {
        bulkChange: '批量更改',
        bulkResponse: '批量回复',
        filter: '筛选器',
        run: '执行',
        reload: '刷新',
        statusChange: '变更状态',
        categoryChange: '变更分类',
        managerChange: '变更负责人',
        confirm: '确认',
        bulkChange1: '针对以下案件ID',
        bulkChange2: '执行',
        itemCount: '显示邮件数量',
        sortConditions: '排序条件',
        ascending: '升序',
        descending: '降序',
        limit: '已超过期限',
        answer: '已回复',
        inHour: '{hour}小时以内',
      },
      // メール詳細ページ
      mailDetails: {
        displaySwitching: '切换显示模式',
        caseId: '依照案件ID绑定显示',
        userId: '依照用户ID绑定显示',
        mobageId: 'mobage Id',
        userAgent: 'User Agent',
      },
      // メール対応ページ
      mailSupport: {
        pastMail: '历史邮件',
        mailContent: '邮件内容',
        template: '模板',
        attachment: '附件',
        save: '暂时保存',
        mailSend: '发送邮件',
        mailSubmitComfirm: '确认发送邮件',
        mailSendingResult: '邮件发送结果',
        supportStart: '开始回复',
      },
      // 分類管理ページ
      manageCategory: {
        title: '分类管理',
      },
      // テンプレート管理ページ
      manageTemplate: {
        title: '模板管理',
        import: '导入',
        export: '导出',
        templateName: '模版名称',
        templateContent: '内容',
        templateSelect: '选择模版',
      },
      // 設定系ワード
      settings: {
        accountList: '账户列表',
        createAccount: '新增管理员账户',
        accountSettings: '账户设置',
        create: '注册新账户',
        accountId: '账户ID',
        displayName: '显示姓名',
        mailAddress: '邮箱地址',
        companyName: '公司名称',
        accountType: '账户类型',
        status: '状态',
        registration: '注册',
        accountActiveSettings: '账户 激活/停权设置',
        run: '激活',
        stop: '停权',
        slackSettings: 'Slack通知设置',
        active: '启用',
        inactive: '停用',
        accountDelete: '删除账户',
        newTab: '在新的分页显示邮件内容',
        emailNotificationSettings: '邮件通知设置',
        // 設定/ゲームタイトル：ワード
        gameTitle: {
          gameTitleList: '游戏列表',
          gameTitleSettings: '游戏设置',
          gameTitileId: '游戏ID',
          gameTitleName: '游戏名称',
          companyName: '公司名称',
          mailAddress: '游戏邮箱',
          autoReplySetting: '邮件自动回复设置',
          forbiddenWordSetting: '禁用语设置',
          excludesEmailSetting: '邮件过滤设定',
          accountList: '账户列表',
          forbiddenWord: '禁用语',
          target: '对象',
          excludeWord: '过滤关键词',
          mailTitle: '邮件标题',
          mailBody: '正文',
        },
      },
    },
  },
};

const i18n = createI18n({
  locale: 'ja',
  messages,
});

export default i18n;
