import { StoreWrapper } from "@/store/storeWrapper";
import { SNACKBAR_TIMEOUT_INF, SNACKBAR_TIMEOUT_ERR } from "@/constants/config";
import { PAGE_INFO, PageInfo, PAGE_INFO_LIST, SNACKBAR_TYPE } from "@/constants/const";
import { Snackbar } from '@/interfaces/Common';
import router from "@/router";
import { Ref } from 'vue';

const store = StoreWrapper.getInstance();

/**
 * メッセージ種別の型
 */
type SnackbarType = typeof SNACKBAR_TYPE[keyof typeof SNACKBAR_TYPE];

/**
 * スナックバーでメッセージを表示する
 * @param snackbar スナックバー
 * @param type メッセージ種別
 * @param text 表示メッセージ
 * @param timeout 表示時間(省略時はデフォルト値を使用)
 * @returns 
 */
export const showSnackBar = (snackbar: Ref<Snackbar>, type: SnackbarType, text: string, timeout: number = 0 , dataList: string[] = []): void => {
  if (!type) return;

  switch (type) {
    case SNACKBAR_TYPE.INFO:
      snackbar.value = {
        color: "success",
        icon: "mdi-checkbox-marked-circle",
        mode: true,
        position: "top",
        timeout: (timeout === 0) ? SNACKBAR_TIMEOUT_INF : timeout,
        text: text,
        dataList: dataList,
        visible: true,
      };
      break;
    case SNACKBAR_TYPE.ERROR:
      snackbar.value = {
        color: "red",
        icon: "mdi-alert-circle",
        mode: true,
        position: "top",
        timeout: (timeout === 0) ? SNACKBAR_TIMEOUT_ERR : timeout,
        text: text,
        dataList: dataList,
        visible: true,
      };
      break;
  }

  return;
};

/**
 * 権限チェック
 * @param pageName - ページ名
 */
export const checkRole = (pageName: string): void => {
  // 指定ページのページ情報を取得
  const pageInfo: PageInfo | undefined = PAGE_INFO_LIST.find((pageInfo) => pageInfo.NAME === pageName);
  // 指定ページ情報が見つからない、またはアカウントの権限が該当しない場合はメール一覧に遷移させる
  if (pageInfo === undefined || !pageInfo.ROLE.includes(store.getAccountRole())) {
    router.push(PAGE_INFO.MAIL.MAIL_LIST.PATH);
    store.setPageName(PAGE_INFO.MAIL.MAIL_LIST.NAME);
  }

  return;
};
