import { AccountInfo, SearchCondition } from '@/store/store';
import { ROLE_TYPE, ROLE_NAME, DISPLAY_TYPE } from '@/constants/const';
import { DisplayType } from '@/axios/mail/mailDetails';
import { BulkMailSupportStoreInfo, MailDetailsStoreInfo, MailSupportStoreInfo } from '@/interfaces/Pages';

/**
 * Storeのラッパークラス
 */
export class StoreWrapper {
  // シングルトン対応
  private static instance: StoreWrapper;

  // メンバ変数
  private store: any;

  // コンストラクタ
  private constructor(store: any) {
    this.store = store;
  }

  static getInstance(store: any = null) {
    if (!StoreWrapper.instance) {
      if (!store) throw new Error('Instance is uninitialized.');
      StoreWrapper.instance = new StoreWrapper(store);
    }

    return StoreWrapper.instance;
  }

  public getStore() {
    return this.store;
  }

  /**
   * ページ名取得
   * @returns {string} ページ名
   */
  public getPageName(): string {
    return this.store.getters.pageName;
  }

  /**
   * トライアルゲームIDリスト取得
   * @returns {string[]} トライアルゲームIDリスト
   */
  public getTrialGameIdList(): string[] {
    return this.store.getters.trialGameIdList;
  }

  /**
   * アカウント情報の取得
   * @returns {AccountInfo} アカウント情報の取得
   */
  public getAccountInfo(): AccountInfo {
    return this.store.getters.accountInfo;
  }

  /**
   * アカウント情報の有無
   * @returns {boolean} アカウント情報の有無
   */
  public isAccountInfo(): boolean {
    const accountInfo = this.store.getters.accountInfo;
    return accountInfo.id && accountInfo.email && accountInfo.role !== ROLE_TYPE.NONE ? true : false;
  }

  /**
   * 認証の有無
   * @returns {boolean} 認証の有無
   */
  public isAuthenticated(): boolean {
    const accountInfo = this.store.getters.accountInfo;
    return accountInfo.isAuthenticated;
  }

  /**
   * アカウントID取得
   * @returns {string} アカウントID
   */
  public getAccountId(): string {
    const accountInfo = this.store.getters.accountInfo;
    return accountInfo.id;
  }

  /**
   * メールアドレス取得
   * @returns {string} メールアドレス
   */
  public getAccountEmail(): string {
    const accountInfo = this.store.getters.accountInfo;
    return accountInfo.email;
  }

  /**
   * 権限取得
   * @returns {number} 権限
   */
  public getAccountRole(): number {
    const accountInfo = this.store.getters.accountInfo;
    return accountInfo.role;
  }

  /**
   * 特定ゲーム紐付きフラグ
   * @returns {boolean} 特定ゲーム紐付きフラグ
   */
  public isLinkedCygames(): boolean {
    const accountInfo = this.store.getters.accountInfo;
    return accountInfo.isLinkedCygames;
  }

  /**
   * アカウント種別名取得
   * @returns {string} アカウント種別名
   */
  public getRoleName(): string {
    return this.store.getters.roleName;
  }

  /**
   * アカウント名取得
   * @returns {string} アカウント名
   */
  public getAccountName(): string {
    return this.store.getters.accountName;
  }

  /**
   * アクセストークンを取得
   * @returns {string} アクセストークン
   */
  public getToken(): string {
    return this.store.getters.token;
  }

  /**
   * 会社名取得
   * @returns {string} 会社名
   */
  public getCompanyName(): string {
    return this.store.getters.companyName;
  }

  /**
   * ゲームID取得
   * @returns {string} ゲームID
   */
  public getGameId(): string {
    return this.store.getters.gameId;
  }

  /**
   * ゲームタイトル取得
   * @returns {string} ゲームタイトル
   */
  public getGameTitle(): string {
    return this.store.getters.gameTitle;
  }

  /**
   * 設定対象のゲームID取得
   * @returns {string} 設定対象のゲームID
   */
  public getSettingsGameId(): string {
    return this.store.getters.settingsGameId;
  }

  /**
   * 問い合わせID取得
   * @returns {string} 問い合わせID
   */
  public getInquiryId(): string {
    return this.store.getters.inquiryId;
  }

  /**
   * メールID取得
   * @returns {number} メールID
   */
  public getMailId(): number {
    return this.store.getters.mailId;
  }

  /**
   * 選択中メールID取得
   * @returns {number} メールID
   */
  public getSelectedMailId(): number {
    return this.store.getters.selectedMailId;
  }

  /**
   * 設定対象のアカウントIDを取得
   * @returns {string} 設定対象のアカウントID
   */
  public getTargetAccountId(): string {
    return this.store.getters.targetAccountId;
  }

  /**
   * アラートの有無を取得
   * @returns {boolean} アラートの有無
   */
  public getIsAlert(): boolean {
    return this.store.getters.isAlert;
  }

  /**
   * アカウント認証の有無を取得
   * @returns {boolean} アカウント認証有無
   */
  public getIsAccountAuthError(): boolean {
    return this.store.getters.isAccountAuthError;
  }

  /**
   * 新規タブ設定フラグ
   * @returns {boolean} 新規タブ設定の適応可否
   */
  public getIsNewTab(): boolean {
    return this.store.getters.isNewTab;
  }

  /**
   * プリセット番号
   * @returns {number | undefined} プリセット番号
   */
  public getPresetId(): number | undefined {
    return this.store.getters.presetId;
  }

  /**
   * 未対応件数を取得
   * @returns {number} 未対応件数
   */
  public getNotificationCount(): number {
    return this.store.getters.notificationCount;
  }

  /**
   * メール一覧：詳細検索情報を取得
   * @returns {SearchCondition} メール詳細情報
   */
  public getSearchCondition(): SearchCondition {
    return this.store.getters.searchCondition;
  }

  /**
   * メール詳細情報を取得
   * @returns {MailDetailsStoreInfo} メール詳細情報
   */
  public getMailDetailsInfo(): MailDetailsStoreInfo {
    return this.store.getters.mailDetailsInfo;
  }

  /**
   * メール対応情報を取得
   * @returns {MailSupportStoreInfo} メール詳細情報
   */
  public getMailSupportInfo(): MailSupportStoreInfo {
    return this.store.getters.mailSupportInfo;
  }

  /**
   * メール対応情報を取得
   * @returns {BulkMailSupportStoreInfo} メール詳細情報
   */
  public getBulkMailSupportInfo(): BulkMailSupportStoreInfo {
    return this.store.getters.bulkMailSupportInfo;
  }

  /**
   * 表示種別を取得
   * @returns {DisplayType} 表示種別
   */
  public getDisplayType(): DisplayType {
    const mailDetailsInfo = this.store.getters.mailDetailsInfo;
    return mailDetailsInfo.displayType;
  }

  /**
   * 案件IDを取得
   * @returns {string} 案件ID
   */
  public getCaseId(): string {
    const mailDetailsInfo = this.store.getters.mailDetailsInfo;
    return mailDetailsInfo.caseId;
  }

  /**
   * ユーザIDを取得
   * @returns {string} ユーザID
   */
  public getUserId(): string {
    const mailDetailsInfo = this.store.getters.mailDetailsInfo;
    return mailDetailsInfo.userId;
  }

  /**
   * ページ名を更新
   */
  public setPageName(value: string) {
    this.store.dispatch('setPageName', value);
  }

  /**
   * トライアルゲームIDリストを更新
   */
  public setTrialGameIdList(value: string[]) {
    this.store.dispatch('setTrialGameIdList', value);
  }

  /**
   * アカウント情報を更新
   */
  public setAccountInfo(value: AccountInfo) {
    this.store.dispatch('setAccountInfo', value);
    // アカウント種別名も変更
    if (value.role === ROLE_TYPE.NONE) this.setRoleName(ROLE_NAME.NONE);
    else if (value.role === ROLE_TYPE.ADMIN) this.setRoleName(ROLE_NAME.ADMIN);
    else if (value.role === ROLE_TYPE.PARTNER_ADMIN) this.setRoleName(ROLE_NAME.PARTNER_ADMIN);
    else if (value.role === ROLE_TYPE.PARTNER) this.setRoleName(ROLE_NAME.PARTNER);
  }

  /**
   * 認証の有無を更新
   */
  public setIsAuthenticated(value: boolean) {
    this.store.dispatch('setIsAuthenticated', value);
  }

  /**
   * アカウントIDを更新
   */
  public setAccountId(value: string) {
    this.store.dispatch('setAccountId', value);
  }

  /**
   * メールアドレスを更新
   */
  public setAccountEmail(value: string) {
    this.store.dispatch('setAccountEmail', value);
  }

  /**
   * アカウント種別を更新
   */
  public setAccountRole(value: number) {
    this.store.dispatch('setAccountRole', value);
    // アカウント種別名も変更
    if (value === ROLE_TYPE.NONE) this.setRoleName(ROLE_NAME.NONE);
    else if (value === ROLE_TYPE.ADMIN) this.setRoleName(ROLE_NAME.ADMIN);
    else if (value === ROLE_TYPE.PARTNER_ADMIN) this.setRoleName(ROLE_NAME.PARTNER_ADMIN);
    else if (value === ROLE_TYPE.PARTNER) this.setRoleName(ROLE_NAME.PARTNER);
  }

  /**
   * アカウント種別を更新
   */
  public setRoleName(value: string) {
    this.store.dispatch('setRoleName', value);
  }

  /**
   * 特定ゲーム紐付きフラグを更新
   */
  public setIsLinkedCygames(value: boolean) {
    this.store.dispatch('setIsLinkedCygames', value);
  }

  /**
   * アカウント名を更新
   */
  public setAccountName(value: string) {
    this.store.dispatch('setAccountName', value);
  }

  /**
   * アクセストークンを更新
   */
  public setToken(value: string) {
    this.store.dispatch('setToken', value);
  }

  /**
   * 会社名を更新
   */
  public setCompanyName(value: string) {
    this.store.dispatch('setCompanyName', value);
  }

  /**
   * ゲームIDを更新
   */
  public setGameId(value: string) {
    this.store.dispatch('setGameId', value);
  }

  /**
   * ゲームタイトルを更新
   */
  public setGameTitle(value: string) {
    this.store.dispatch('setGameTitle', value);
  }

  /**
   * 設定対象のゲームIDを更新
   */
  public setSettingsGameId(value: string) {
    this.store.dispatch('setSettingsGameId', value);
  }

  /**
   * 問い合わせIDを更新
   */
  public setInquiryId(value: string) {
    this.store.dispatch('setInquiryId', value);
  }

  /**
   * メールIDを更新
   */
  public setMailId(value: number) {
    this.store.dispatch('setMailId', value);
  }

  /**
   * 選択中メールIDを更新
   */
  public setSelectedMailId(value: number) {
    this.store.dispatch('setSelectedMailId', value);
  }

  /**
   * 設定対象のアカウントIDを更新
   */
  public setTargetAccountId(value: string) {
    this.store.dispatch('setTargetAccountId', value);
  }

  /**
   * 未対応件数を更新
   */
  public setCount(value: number) {
    this.store.dispatch('setCount', value);
  }

  /**
   * アラートの有無を更新
   */
  public setIsAlert(value: boolean) {
    this.store.dispatch('setIsAlert', value);
  }

  /**
   * アカウント認証エラーの有無を更新
   */
  public setIsAccountAuthError(value: boolean) {
    this.store.dispatch('setIsAccountAuthError', value);
  }

  /**
   * 新規タブ設定を更新
   */
  public setIsNewTab(value: boolean) {
    this.store.dispatch('setIsNewTab', value);
  }

  /**
   * プリセット番号
   */
  public setPresetId(value: number | undefined) {
    this.store.dispatch('setPresetId', value);
  }

  /**
   * メール一覧：詳細検索情報を更新
   */
  public setSearchCondition(value: SearchCondition) {
    this.store.dispatch('setSearchCondition', value);
  }

  /**
   * メール詳細情報を更新
   */
  public setMailDetailsInfo(value: MailDetailsStoreInfo) {
    this.store.dispatch('setMailDetailsInfo', value);
  }

  /**
   * メール対応情報を更新
   */
  public setMailSupportInfo(value: MailSupportStoreInfo) {
    this.store.dispatch('setMailSupportInfo', value);
  }

  /**
   * メール一括対応情報を更新
   */
  public setBulkMailSupportInfo(value: BulkMailSupportStoreInfo) {
    this.store.dispatch('setBulkMailSupportInfo', value);
  }

  /**
   * メール詳細：表示種別を更新
   */
  public setMailDetailsDisplayType(value: string) {
    this.store.dispatch('setMailDetailsDisplayType', value);
  }

  /**
   * メール詳細：案件IDを更新
   */
  public setMailDetailsCaseId(value: string) {
    this.store.dispatch('setMailDetailsCaseId', value);
  }

  /**
   * メール詳細：ユーザーIDを更新
   */
  public setMailDetailsUserId(value: string) {
    this.store.dispatch('setMailDetailsUserId', value);
  }

  /// メソッド ///

  /**
   * 管理アカウントの有無を取得
   * @returns {boolean} 管理アカウントの有無
   */
  public isAdminAccount(): boolean {
    const accountInfo = this.store.getters.accountInfo;
    return accountInfo.role === ROLE_TYPE.ADMIN ? true : false;
  }

  /**
   * パートナーアカウントの有無を取得
   * @returns {boolean} パートナーアカウントの有無
   */
  public isPartnerAccount(): boolean {
    const accountInfo = this.store.getters.accountInfo;
    return accountInfo.role === ROLE_TYPE.PARTNER_ADMIN || accountInfo.role === ROLE_TYPE.PARTNER ? true : false;
  }

  /**
   * トライアルゲームIDの有無を取得
   * @returns {boolean} トライアルゲームIDの有無
   */
  public isTrialGameId(gameId: string): boolean {
    return this.getTrialGameIdList().includes(gameId);
  }

  /**
   * アカウント情報を初期化
   */
  public clearAccountInfo() {
    const accountInfo: AccountInfo = {
      isAuthenticated: false,
      id: '',
      email: '',
      role: ROLE_TYPE.NONE,
      isLinkedCygames: false
    };

    this.setAccountInfo(accountInfo);
  }

  /**
   * 詳細検索情報を初期化
   */
  public clearSearchCondition() {
    const searchCondition: SearchCondition = {
      isDetailedSearch: false,
      pageNum: 0,
      gameTitleInfoList: new Array(),
      mailInfoList: new Array(),
      statusList: new Array(),
      categoryList: new Array(),
      managerList: new Array(),
      mailOption: {
        limit: 0,
        offset: 0,
        mailCount: 0,
      },
      filterSettingInfo: {
        gameId: '',
        limit: 0,
        offset: 0,
        sendDateStart: null,
        sendDateEnd: null,
        inquiryId: null,
        caseId: null,
        userId: null,
        answerLimitList: [],
        statusIdList: [],
        categoryIdList: [],
        managerIdList: [],
        subject: null,
        mailBody: null,
        logDetail: null,
        comment: null,
        sort: '',
        isAsc: false,
      },
      searchSettingInfo: {
        gameId: '',
        limit: 0,
        offset: 0,
        sendDateStart: null,
        sendDateEnd: null,
        inquiryId: null,
        caseId: null,
        userId: null,
        answerLimitList: [],
        statusIdList: [],
        categoryIdList: [],
        managerIdList: [],
        subject: null,
        mailBody: null,
        logDetail: null,
        comment: null,
        sort: '',
        isAsc: false,
      },
      presetInfoList: [
        {
          presetId: 1,
          presetItems: [
            { presetKey: 'sendDateStart', presetValue: null },
            { presetKey: 'sendDateEnd', presetValue: null },
            { presetKey: 'inquiryId', presetValue: null },
            { presetKey: 'caseId', presetValue: null },
            { presetKey: 'userId', presetValue: null },
            { presetKey: 'answerLimitList', presetValue: [] },
            { presetKey: 'statusIdList', presetValue: [] },
            { presetKey: 'categoryIdList', presetValue: [] },
            { presetKey: 'managerIdList', presetValue: [] },
            { presetKey: 'subject', presetValue: null },
            { presetKey: 'mailBody', presetValue: null },
            { presetKey: 'logDetail', presetValue: null },
            { presetKey: 'comment', presetValue: null },
          ],
        },
        {
          presetId: 2,
          presetItems: [
            { presetKey: 'sendDateStart', presetValue: null },
            { presetKey: 'sendDateEnd', presetValue: null },
            { presetKey: 'inquiryId', presetValue: null },
            { presetKey: 'caseId', presetValue: null },
            { presetKey: 'userId', presetValue: null },
            { presetKey: 'answerLimitList', presetValue: [] },
            { presetKey: 'statusIdList', presetValue: [] },
            { presetKey: 'categoryIdList', presetValue: [] },
            { presetKey: 'managerIdList', presetValue: [] },
            { presetKey: 'subject', presetValue: null },
            { presetKey: 'mailBody', presetValue: null },
            { presetKey: 'logDetail', presetValue: null },
            { presetKey: 'comment', presetValue: null },
          ],
        },
        {
          presetId: 3,
          presetItems: [
            { presetKey: 'sendDateStart', presetValue: null },
            { presetKey: 'sendDateEnd', presetValue: null },
            { presetKey: 'inquiryId', presetValue: null },
            { presetKey: 'caseId', presetValue: null },
            { presetKey: 'userId', presetValue: null },
            { presetKey: 'answerLimitList', presetValue: [] },
            { presetKey: 'statusIdList', presetValue: [] },
            { presetKey: 'categoryIdList', presetValue: [] },
            { presetKey: 'managerIdList', presetValue: [] },
            { presetKey: 'subject', presetValue: null },
            { presetKey: 'mailBody', presetValue: null },
            { presetKey: 'logDetail', presetValue: null },
            { presetKey: 'comment', presetValue: null },
          ],
        },
        {
          presetId: 4,
          presetItems: [
            { presetKey: 'sendDateStart', presetValue: null },
            { presetKey: 'sendDateEnd', presetValue: null },
            { presetKey: 'inquiryId', presetValue: null },
            { presetKey: 'caseId', presetValue: null },
            { presetKey: 'userId', presetValue: null },
            { presetKey: 'answerLimitList', presetValue: [] },
            { presetKey: 'statusIdList', presetValue: [] },
            { presetKey: 'categoryIdList', presetValue: [] },
            { presetKey: 'managerIdList', presetValue: [] },
            { presetKey: 'subject', presetValue: null },
            { presetKey: 'mailBody', presetValue: null },
            { presetKey: 'logDetail', presetValue: null },
            { presetKey: 'comment', presetValue: null },
          ],
        },
        {
          presetId: 5,
          presetItems: [
            { presetKey: 'sendDateStart', presetValue: null },
            { presetKey: 'sendDateEnd', presetValue: null },
            { presetKey: 'inquiryId', presetValue: null },
            { presetKey: 'caseId', presetValue: null },
            { presetKey: 'userId', presetValue: null },
            { presetKey: 'answerLimitList', presetValue: [] },
            { presetKey: 'statusIdList', presetValue: [] },
            { presetKey: 'categoryIdList', presetValue: [] },
            { presetKey: 'managerIdList', presetValue: [] },
            { presetKey: 'subject', presetValue: null },
            { presetKey: 'mailBody', presetValue: null },
            { presetKey: 'logDetail', presetValue: null },
            { presetKey: 'comment', presetValue: null },
          ],
        },
      ],
    };

    this.setSearchCondition(searchCondition);
  }

  /**
   * メール詳細情報を初期化
   */
  public clearMailDetailsInfo() {
    const mailDetailsInfo: MailDetailsStoreInfo = {
      displayType: DISPLAY_TYPE.NONE,
      caseId: '',
      userId: '',
      mailId: 0,
      inquiryId: '',
      gameId: '',
      gameTitle: '',
      statusInfoList: new Array(),
      categoryInfoList: new Array(),
      managerInfoList: new Array(),
    };

    this.setMailDetailsInfo(mailDetailsInfo);
  }

  /**
   * メール対応情報を初期化
   */
  public clearMailSupportInfo() {
    const mailSupportInfo: MailSupportStoreInfo = {
      displayType: DISPLAY_TYPE.NONE,
      caseId: '',
      userId: '',
      mailId: 0,
      inquiryId: '',
      gameId: '',
      gameTitle: '',
    };

    this.setMailSupportInfo(mailSupportInfo);
  }

  /**
   * メール一括対応情報を初期化
   */
  public clearBulkMailSupportInfo() {
    const bulkMailSupportInfo: BulkMailSupportStoreInfo = {
      caseIdList: new Array(),
      mailIdList: new Array(),
      gameId: '',
      gameTitle: '',
    };
    this.setBulkMailSupportInfo(bulkMailSupportInfo);
  }

  /**
   * storeの全情報を初期化
   */
  public clearStoreData() {
    // ページ名をクリア
    this.setPageName('');

    // トライアルゲームIDリストをクリア
    this.setTrialGameIdList(new Array());

    // アカウント種別をクリア
    this.setRoleName(ROLE_NAME.NONE);

    // アカウント名をクリア
    this.setAccountName('');

    // アクセストークンをクリア
    this.setToken('');

    // 会社名をクリア
    this.setCompanyName('');

    // 未対応件数をクリア
    this.setCount(0);

    // ゲームIDをクリア
    this.setGameId('');

    // ゲームタイトルをクリア
    this.setGameTitle('');

    // 設定対象のゲームIDをクリア
    this.setSettingsGameId('');

    // 問い合わせIDをクリア
    this.setInquiryId('');

    // メールIDをクリア
    this.setMailId(0);

    // 選択中メールIDをクリア
    this.setSelectedMailId(0);

    // 設定対象のアカウントIDをクリア
    this.setTargetAccountId('');

    // ページ遷移確認フラグをクリア
    this.setIsAlert(false);

    // アカウント認証フラグをクリア
    this.setIsAccountAuthError(false);

    // 新規タブ設定フラグをクリア
    this.setIsNewTab(false);

    // プリセット番号
    this.setPresetId(undefined);

    // アカウント情報をクリア
    this.clearAccountInfo();

    // メール一覧：詳細検索情報をクリア
    this.clearMailDetailsInfo();

    // メール詳細情報をクリア
    this.clearSearchCondition();

    // メール対応情報をクリア
    this.clearMailSupportInfo();

    // メール一括対応情報をクリア
    this.clearBulkMailSupportInfo();
  }
}
